<template>

  <div>

    <Header />
   <!--::breadcrumb part start::-->
   <section class="breadcrumb" :style="{ backgroundImage: 'url('+ topo_bg + ')', height:'300px', backgroundRepeat:'no-repeat', backgroundSize: 'cover' }">
      <div class="container">
         <div class="row">
            <div class="col-lg-12">
               <div class="breadcrumb_iner">
                  <div class="breadcrumb_iner_item">
                     <h2>{{topoTitulo}}</h2>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!--::breadcrumb part start::-->

 <!--::team part end::-->
 <section class="about_part" style="padding-top: 60px;">
      <div class="container">
         <div class="row">
            <div class="section_tittle">
               <h2>Vídeos</h2>
            </div>
         </div>
      </div>
   </section>
   <!--::team part end::-->
<!--================Blog Area =================-->
<section class="blog_area" style="padding-bottom: 60px;">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 mb-5 mb-lg-0">
          <div class="blog_left_sidebar">
            <article class="blog_item">
              <div class="blog_details">
                <a class="d-inline-block" href="single-blog.html">
                  <h2>{{ blogTitulo }}</h2>
                </a>
                <p v-html="blogDescricao"></p>
                <div class="row mt-3">
                  <div class="col-lg-12 col-md-12" style="text-align: center;">
                    <iframe width="100%" height="400" :src="blogVideo" title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                  </div>
              </div>
              </div>
            </article>

         </div>
        </div>
        <div class="col-lg-4">
          <div class="blog_right_sidebar">
            <aside class="single_sidebar_widget popular_post_widget">
              <h3 class="widget_title">Últimos Vídeos</h3>
              <div v-for="noticia in blog" :key="noticia.institucionalId" class="media post_item">
                <div class="media-body">
                  <a href="#" @click.prevent="carregaNoticia(noticia.institucionalId)" style="display: inline-flex;">
                    <i class="fab fa-youtube" style="font-size: 18px;padding-right: 3px;"></i>
                    <h3>{{ noticia.nome }}</h3>
                  </a>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--================Blog Area =================-->


  </div>
</template>

<script>
import Header from '../shared/Header.vue'
// import Depoimentos from '../shared/Depoimentos.vue'
import moment from 'moment'

export default {
  components: {
    Header
  },
  data() {
    return {
      contato: [],
      topoTitulo:'',
      textoTitulo:'',
      topo_bg:'',

      blog: [],
      blogTitulo: '',
      blogDescricao: '',
      blogVideo:'',
      topoEventos: [],
      EventoTitulo:'',
      EventoTexto:'',
    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/videostopo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome
      this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/videoslista")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.blog = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                        if (a.ordem > b.ordem) {
                            return 1;
                        }
                        if (a.ordem < b.ordem) {
                            return -1;
                        }
                        return 0;
                    }).reverse();

        this.blogTitulo = this.blog[0].nome
        this.blogDescricao = this.blog[0].resumo1
        this.blogVideo = this.blog[0].linkBtn1
      });

    this.carregaNoticia(this.$route.params.idNoticia) 

  },
  methods: {
     carregaNoticia(idNoticia) {   
      this.$http
      .get(this.$apiUrl + "/institucional/" + idNoticia)
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.noticia = retornoAPI

        this.blogTitulo = this.noticia.nome
        this.blog_img = this.$apiUrlIndex + this.noticia.institucionalImg[0].urlImagem
        this.blogDescricao = this.noticia.descricao1
        this.blogData = this.noticia.data1

        if (this.noticia.institucionalImg[0] != null) {          
          this.blog_img = this.$apiUrlIndex + this.noticia.institucionalImg[0].urlImagem;
        }
        else {          
          this.blog_img = '';
        }
      });
    },
    currentDateTime(vdate) {
      return moment(vdate).format('DD/MM/YYYY')
    }
  }
  
}

</script>

