<template>
    <div>
        <section class="" style="min-height:600px;padding-top: 0px; padding-bottom: 150px;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-2"
                        style="background-color: #cccccc;padding-top: 20px;padding-left: 10px;padding-right: 10px;">
                        <div v-if="Turno != ''"
                            style="border:1px solid #cccccc;border-radius: 5px;background-color: #ffffff;padding: 10px;font-size: 14px;">
                            <div style="padding: 10px; text-align: center;">
                                <img src="/img/logo.png" />
                            </div>
                            <ul class="lista-sidebar_links" style="padding-left: 0rem !important;line-height: 1.8;">
                                <li><strong>Turno:</strong> {{ Turno[0].tiposTurnos.descricao }}</li>
                                <li><strong>Início:</strong> {{ FormatDateTime2(Turno[0].dataInicial) }}</li>
                                <li><strong>Usuário:</strong> {{ Turno[0].usuariosSis.usuario }}</li>
                                <li><strong>Matrícula:</strong> {{ Turno[0].usuariosSis.matricula }}</li>
                                <li v-if="Alfas != '' && Alfas != null && Turno[0].alfa1 !=null"><strong>Alfa 1:</strong> {{ Alfas.filter(x => x.alfasId ==
                                    Turno[0].alfa1) != null ? Alfas.filter(x => x.alfasId ==
                                    Turno[0].alfa1)[0].descricao : '' }}</li>
                                <li v-if="Alfas != '' && Alfas != null && Turno[0].alfa2 !=null"><strong>Alfa 2:</strong> {{ Alfas.filter(x => x.alfasId ==
                                    Turno[0].alfa2) != null ? Alfas.filter(x => x.alfasId ==
                                    Turno[0].alfa2)[0].descricao : '' }}</li>
                            </ul>
                        </div>

                        <ul class="lista-sidebar_links" style="padding-left: 1rem !important;padding-top: 20px;">
                            <h5>Chamados</h5>
                            <li><router-link to="/ControleChamados" class="texto" style="font-size: 14px;">Turno
                                    Atual</router-link></li>
                            <li><router-link to="/ChamadosPendentes" class="texto" style="font-size: 14px;">Chamados
                                    Pendentes<small style="color:red">({{ itemsQtde }})</small></router-link></li>
                            <li><router-link to="/ChamadosPesquisa" class="texto" style="font-size: 14px;">Lista
                                    Chamados</router-link></li>
                            <li v-if="UsuarioLogado.tipo == 1"><router-link to="/Indicadores" class="texto"
                                    style="font-size: 14px;">Indicadores</router-link></li>
                            <hr>
                        </ul>
                    </div>
                    <div class="col-lg-10 mt-5 mt-lg-0">
                        <div class="container-fluid" style="padding-top: 20px;">
                            <div class="row">
                                <div class="col-lg-6">
                                    <button class="btn btn-secondary" @click="Logout()">
                                        <i class="fas fa-sign-out-alt"></i> Sair
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="container">
                            <form class="form-contact contact_form" id="contactForm" novalidate="novalidate" style="padding-top: 30px;">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <small>Data Inicial</small>
                                            <input class="form-control" v-model="DataInicial" type="date" placeholder="Data Inicial" style="Height: 35px !important;">
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <small>Data Final</small>
                                            <input class="form-control" v-model="DataFinal" type="date" placeholder="Data Final" style="Height: 35px !important;">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group mt-3">
                                            <button type="button" class="button button-contactForm" @click="PesquisarIndicadores()">Pesquisar</button>
                                        </div>
                                    </div>
                                </div>
                                </form>
                            <div ref="containerToConvert" v-if="QtdePorOcorrenica!=''">
                                <!-- Cards com indicadores -->
                                <div class="row" style="padding-top: 30px;">
                                    <h3 class="titulo-principal text-center mb-4">Indicadores Ocorrências <small>{{ DataInicial != '' ? ' - ' + FormatDate(DataInicial) + ' até ' : '' }}  {{ DataFinal != '' ? FormatDate(DataFinal) : '' }}</small></h3>
                                    <div class="col-md-3">
                                        <div class="card">
                                            <div class="card-body" style="padding: 2px 10px">
                                                <h5 class="card-title" style="font-size: 13px;">Qtde Período</h5>
                                                <p class="card-text">{{ QtdePeriodo }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="card">
                                            <div class="card-body" style="padding: 2px 10px">
                                                <h5 class="card-title" style="font-size: 13px;">Média Mês</h5>
                                                <p class="card-text">{{ MediaMes.toFixed(2) }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="card">
                                            <div class="card-body" style="padding: 2px 10px">
                                                <h5 class="card-title" style="font-size: 13px;">Média Dia</h5>
                                                <p class="card-text">{{ MediaDia.toFixed(2) }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="card">
                                            <div class="card-body" style="padding: 2px 10px">
                                                <h5 class="card-title" style="font-size: 13px;">Média Turno</h5>
                                                <p class="card-text">{{ MediaTurno.toFixed(2) }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Tabela com tipo de ocorrência e quantidade -->
                                <div class="row mt-4">
                                    <div class="col-md-6">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Tipo de Ocorrência</th>
                                                    <th>Quantidade</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- Use Vue.js para preencher os dados da tabela dinamicamente -->
                                                <tr v-for="(item, index) in QtdePorOcorrenica" :key="index">
                                                    <td>{{ item[0] }}</td>
                                                    <td>{{ item[1] }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <!-- Gráfico de pizza por tipo de ocorrência -->
                                    <div class="col-md-6" v-if="QtdePorOcorrenica!=''">
                                        <h3>Ocorrências por Tipo</h3>
                                        <!-- Use Vue.js para renderizar o gráfico dinamicamente  -->
                                        <GChart type="PieChart" :options="options" :data="chartData"/>
                                        <br>
                                        <br>
                                        <h3>Ocorrências por Usuário</h3>
                                        <!-- Use Vue.js para renderizar o gráfico dinamicamente  -->
                                        <GChart type="PieChart" :options="options" :data="chartData2"/>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4" v-if="QtdePorOcorrenica!=''">
                                <div class="col-md-12" style="text-align: center;">
                                    <hr>
                                    <button type="button" class="button button-contactForm" @click="generatePDF()">
                                        <i class="fas fa-print"></i> Gerar PDF
                                    </button>
                                </div>
                            </div> 
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import moment from 'moment';
import { GChart } from 'vue-google-charts/legacy'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default {
    components: {
       GChart
    },
    data() {
        return {
            Turno: [],
            MsgTurno: '',
            Alfas: [],
            selecioneAlfa1: '',
            selecioneAlfa2: '',
            TiposTurnos: [],
            selecioneTipoTurno: '',
            StatusIniciarTurno: false,
            StatusFinalizarTurno: false,
            StatusCadastrarTurno: false,
            UsuarioLogado: [],
            Ocorrencia: {
                ChamadosTurnosId: 0,
                Turnos: { TurnosId: '' },
                UsuariosSis: { UsuariosSisId: '' },
                TiposOcorrencias: { TiposOcorrenciasId: '' },
                DataHoraRegistro: '',
                DataHoraOcorrencia: '',
                DescricaoOcr: '',
                OcrOcorrencia: '',
                AcaoTomada: '',
                StatusOcorrencias: { StatusOcorrenciasId: '' }
            },
            TipoOcorrencia: [],
            StatusOcorrencia: [],
            SelecioneTipoOcorrencia: '',
            SelecioneStatusOcorrencia: '',
            items: [],
            LiberaAlteracao: false,
            DataFinal:'',
            DataInicial:'',
            //
            QtdePeriodo:0,
            MediaMes:0,
            MediaDia:0,
            MediaTurno:0,
            QtdePorOcorrenica:[],
            //
            options: {
                pieHole: 0.2,
                legend: { textStyle: { fontSize: "12" }, position: "right" },
                chartArea: { width: "100%", height: "100%" },
            },
            chartData: [],
            chartData2:[],
            QtdePorUsuario:[],
            itemsQtde:0,
        }
    },
    methods: {
        Logout() {
            console.log('aqui')
            this.$cookie.delete(this.$DadosCookieSis);
            this.$redirect("/LoginSistema");
        },
        CheckLoginSistema() {
            let _dados = {
                nomeUsuario: '',
                usuarioId: '',
                usuario: '',
                senha: '',
                tipo: '',
            }
            _dados = JSON.parse(this.$cookie.get(this.$DadosCookieSis));
            if (_dados == null) {
                this.$redirect("/LoginSistema");
            }
            else {
                this.UsuarioLogado = _dados;
            }
        },
        currentDateTime(vdate) {
            if (vdate != null) {
                return moment(vdate).format('LL');
            }
            else {
                return '';
            }

        },
        FormatDateTime(vdate) {
            moment.locale("pt-br");
            return moment(vdate).format('DD/MM/YYYY HH:mm')
        },
        FormatDateTime2(vdate) {
            moment.locale("pt-br");
            return moment(vdate).subtract(3, 'hours').format('DD/MM/YYYY HH:mm')
        },
        FormatDate(vdate) {
            moment.locale("pt-br");
            return moment(vdate).format('DD/MM/YYYY')
        },
        PesquisarIndicadores(){
            if (this.DataInicial == "") {
            this.$mensagem_normal("Preencha Data Inicial");
            } else if (this.DataFinal == "") {
                this.$mensagem_normal("Preencha Data Final");
            } else {
                this.chartData = [];
                this.chartData2 = [];
                this.$http.get(this.$apiUrl + "/ChamadosTurnos")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.items = retornoAPI
                    console.log('this.items')
                    console.log(this.items)
                this.itemsFiltro = this.items.filter(x => x.dataHoraOcorrencia >= this.DataInicial && x.dataHoraOcorrencia <= moment(this.DataFinal, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD'))
                     console.log('this.itemsFiltro')
                    console.log(this.itemsFiltro)
                  //
                  this.QtdePeriodo = this.itemsFiltro.length;
                  //
                  const dataInicial = moment(this.DataInicial);
                  const dataFinal = moment(this.DataFinal).add(1, 'days'); 
                  const quantidadeDeDias = dataFinal.diff(dataInicial, 'days');
                  const quantidadeDeMeses = dataFinal.diff(dataInicial, 'months');
                  console.log('quantidadeDeDias')
                  console.log(quantidadeDeDias)
                  console.log('quantidadeDeMeses')
                  console.log(quantidadeDeMeses)
                  this.MediaMes = this.itemsFiltro.length/(quantidadeDeMeses == 0 ? 1 : quantidadeDeMeses+1);
                  //
                  this.MediaDia = this.itemsFiltro.length/(quantidadeDeDias == 0 ? 1 : quantidadeDeDias+1)
                  //
                  this.MediaTurno = this.MediaDia/2;
                  //

                  const descricaoQuantidade = {};

                // Iterar sobre os chamados e contar as descrições dos tipos de ocorrências
                this.itemsFiltro.forEach(chamado => {
                const descricao = chamado.tiposOcorrencias.descricao;

                if (descricaoQuantidade[descricao]) {
                    descricaoQuantidade[descricao]++;
                } else {
                    descricaoQuantidade[descricao] = 1;
                }
                });

                // Filtrar apenas as descrições com contagem maior que 0
                const descricaoQuantidadeFiltrada = {};

                for (const descricao in descricaoQuantidade) {
                if (descricaoQuantidade[descricao] > 0) {
                    descricaoQuantidadeFiltrada[descricao] = descricaoQuantidade[descricao];
                }
                }
                // Transforme o objeto em uma matriz de pares chave-valor
                const QtdeArray = Object.entries(descricaoQuantidadeFiltrada);

                // Ordene a matriz com base nas chaves (descrições)
                QtdeArray.sort((a, b) => {
                return a[0].localeCompare(b[0]);
                });
                this.QtdePorOcorrenica = QtdeArray
                this.chartData.push(['Descrição', 'Quantidade']);
                this.chartData.push(...this.QtdePorOcorrenica);

                //
                
                
                // Iterar sobre os chamados e contar as usuarios quantidade ocorrências

                const usuariodescricaoQuantidade = {};
                this.itemsFiltro.forEach(chamado => {
                const usuariodescricao = chamado.usuariosSis.usuario;

                if (usuariodescricaoQuantidade[usuariodescricao]) {
                    usuariodescricaoQuantidade[usuariodescricao]++;
                } else {
                    usuariodescricaoQuantidade[usuariodescricao] = 1;
                }
                });

                // Filtrar apenas as descrições com contagem maior que 0
                const usuariodescricaoQuantidadeFiltrada = {};

                for (const usuariodescricao in usuariodescricaoQuantidade) {
                if (usuariodescricaoQuantidade[usuariodescricao] > 0) {
                    usuariodescricaoQuantidadeFiltrada[usuariodescricao] = usuariodescricaoQuantidade[usuariodescricao];
                }
                }
                // Transforme o objeto em uma matriz de pares chave-valor
                const usuarioQtdeArray = Object.entries(usuariodescricaoQuantidadeFiltrada);
                usuarioQtdeArray.sort((a, b) => {
                return a[0].localeCompare(b[0]);
                });
                this.QtdePorUsuario = usuarioQtdeArray
                this.chartData2.push(['Nome', 'Quantidade']);
                this.chartData2.push(...this.QtdePorUsuario);

                });
            }
        },
        /*async generatePDF() {
            const content = this.$refs.containerToConvert;

            const pdf = new jsPDF({
                    orientation: 'landscape',
                    unit: 'mm',
                    format: 'a4',
                });

                const canvas = await html2canvas(content);
                const imgData = canvas.toDataURL('image/png');

                pdf.addImage(imgData, 'PNG', 10, 10);
                pdf.save('documento.pdf');
        },*/
        async generatePDF() {
        const content = this.$refs.containerToConvert;

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4',
        });

        const canvas = await html2canvas(content);
        const imgData = canvas.toDataURL('image/png');

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        let newWidth = pdfWidth - 20; // 10px de margem para cada lado
        let newHeight = (imgHeight * newWidth) / imgWidth;

        if (newHeight > pdfHeight) {
            newHeight = pdfHeight;
            newWidth = (imgWidth * newHeight) / imgHeight;
        }

        const x = (pdfWidth - newWidth) / 2;
        const y = 10; // Posição 10mm do topo, ajuste conforme necessário

        pdf.addImage(imgData, 'PNG', x, y, newWidth, newHeight);
        pdf.save('documento.pdf');
    }
    },
    created() {
        this.CheckLoginSistema();

       

        this.$http
            .get(this.$apiUrl + "/Turnos")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.Turno = retornoAPI.filter(x => x.situacao == 'A')

                console.log('this.Turno')
                console.log(this.Turno)

                if (this.Turno != "") {
                    if (this.UsuarioLogado.usuarioId == this.Turno[0].usuariosSis.usuariosSisId || this.UsuarioLogado.tipo == 1) {
                        this.LiberaAlteracao = true;
                    }

                }
                else {
                    if (this.UsuarioLogado.tipo == 1) {
                        this.LiberaAlteracao = true;
                    }
                }
            });

        this.$http
            .get(this.$apiUrl + "/Alfas")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.Alfas = retornoAPI.filter(x => x.visivel)
                console.log('this.Alfas')
                console.log(this.Alfas)
            });

        this.$http
            .get(this.$apiUrl + "/TiposTurnos")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.TiposTurnos = retornoAPI.filter(x => x.visivel)
            });

        this.$http
            .get(this.$apiUrl + "/TiposOcorrencias")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.TipoOcorrencia = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                    if (a.descricao > b.descricao) {
                        return 1;
                    }
                    if (a.descricao < b.descricao) {
                        return -1;
                    }
                    return 0;
                });
            });

        this.$http
            .get(this.$apiUrl + "/StatusOcorrencias")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.StatusOcorrencia = retornoAPI.filter(x => x.visivel)
            });

            this.$http
        .get(this.$apiUrl + "/ChamadosTurnos/Status/1")
        .then((res) => res.json())
        .then((retornoAPI) => {
            this.itemsQtde = retornoAPI.length
        });
    }
}

</script>

<style>
@import '/css_cart/carrinho.css';
@import '/css_cart/minha_conta.css';


@media (min-width: 976px) {
    .modal-dialog {
        max-width: 700px !important;
        margin: 1.75rem auto;
    }
}</style>