<template>
    <div>
        <Header />
        <section class="carrinho">

            <section class="main-minha_conta">
                <CheckAcesso />
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3" style="background-color: #cccccc;padding: 20px;">
                            <ul class="lista-sidebar_links">
                                <h5>Geral</h5>
                                <li><router-link to="/meusDados" class="texto">Meus Dados</router-link></li>
                                <li><router-link to="/mural" class="texto">Mural Recados</router-link></li>
                                <li><router-link to="/contatosInterno" class="texto">Contatos</router-link></li>
                                <hr>
                                <h5>Informações</h5>
                                <li><router-link to="/atas" class="texto">Atas</router-link></li>
                                <li><router-link to="/contratos" class="texto">Contratos</router-link></li>
                                <li><router-link to="/estatuto" class="texto">Estatuto</router-link></li>
                                <hr>
                                <h5>Financeiro</h5>
                                <li><router-link to="/extratos" class="texto">Extratos</router-link></li>
                                <li><router-link to="/informativos" class="texto">Informativos</router-link></li>
                                <hr>
                                <h5>Comunicação</h5>
                                <li><router-link to="/eventosInterno" class="texto active">Eventos</router-link></li>
                                <li><router-link to="/acoesInterno" class="texto">Ações</router-link></li>
                                <hr>
                            </ul>
                        </div>
                        <div class="col-lg-8 mt-5 mt-lg-0">
                            <h3 class="titulo-principal text-center mb-4">Eventos</h3>
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <small>Ano</small>
                                        <select v-model="selectedYear" class="form-control">
                                            <option v-for="(year, index) in years" :value="year" :key="index">{{ year }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-4">
                                        <small>Mês</small>
                                        <select v-model="selectedMonth" class="form-control">
                                            <option v-for="(month, index) in months" :value="month" :key="index">{{ month }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2" style="padding-top: 24px">
                                        <button @click="filterArray"
                                            style="background-color: rgb(204, 153, 51);padding-right: 20px !important;padding-bottom: 7px !important;padding-left: 20px !important; padding-top: 7px;border-radius: 6px; color: rgb(255, 255, 255); border: none;">Filtrar</button>
                                    </div>
                                    <div class="col-lg-2" style="padding-top: 24px">
                                        <button @click="cleanArray"
                                            style="background-color: #3394cc;padding-right: 20px !important;padding-bottom: 7px !important;padding-left: 20px !important; padding-top: 7px;border-radius: 6px; color: rgb(255, 255, 255); border: none;">Limpar</button>
                                    </div>
                                </div>
                                    <div class="row" v-for="item in myArray" :key="item.institucionalId" style="padding-top: 30px;">
                                        <div class="col-lg-6 mt-4 mb-4">
                                            <strong style="color:#000000;">{{ item.nome }} </strong><br>
                                            <div style="color:#000000;">Data: {{ FormatDateTime(item.data1) }}</div>
                                            <p v-html="item.resumo1" style="padding: 5px;"></p>
                                        </div>
                                        <div v-if="item.campoExtra1!='' && item.campoExtra1!=null" class="col-lg-6">
                                            <iframe v-if="item.campoExtra1!='' && item.campoExtra1!=null" width="100%" height="315" :src="item.campoExtra1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                        <div v-if="item.institucionalImg.length == 1" class="col-lg-6 mt-4 mb-4" style="text-align: center;">
                                          <img :src="$apiUrlIndex + item.institucionalImg[0].urlImagem" alt="" title="" v-viewer="{navbar: false, toolbar:false, title: false}">
                                        </div>
                                        <div v-else class="col-lg-12">
                                            <div class="images" v-viewer style="display: inline;">
                                                <img v-for="src in item.institucionalImg" :src="$apiUrlIndex + src.urlImagem"
                                                :key="src.institucionalImgId" style="height: 90px;padding: 10px;border-radius: 15px;cursor: pointer;">
                                            </div>
                                        </div>
                                       
                                        <hr>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </div>
</template>

<script>
import Header from '../shared/Header.vue'
import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'
import moment from 'moment';
import 'viewerjs/dist/viewer.css';

export default {
    components: {
        CheckAcesso,
        Header,
    },
    data() {
        return {
            selectedYear: null,
            selectedMonth: null,
            years: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], // exemplo de anos disponíveis
            months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'], // exemplo de meses disponíveis
            eventosinternos: [],
            myArray: [],
            options: {},
            images: [],
            viewerVisible:false,
        }
    },
    methods: {
        currentDateTime(vdate) {
            if (vdate != null) {
                return moment(vdate).format('LL');
            }
            else {
                return '';
            }

        },
        FormatDateTime(vdate) {
            moment.locale("pt-br");
            return moment(vdate).format('DD/MM/YYYY')
        },
        filterArray() {
            const filteredArray = this.eventosinternos.filter(item => {
                const itemDate = new Date(item.data1); // supondo que o objeto tem um atributo "date"
                return itemDate.getFullYear() === this.selectedYear && itemDate.getMonth() === this.months.indexOf(this.selectedMonth);
            });
            this.myArray = filteredArray; // faça o que quiser com o array filtrado
        },
        cleanArray() {
            this.myArray = this.eventosinternos;
            this.selectedYear = null;
            this.selectedMonth = null;
        },
        showViewer(value) {
            const _images = value.map(image => {
                return {
                    src: image.urlImagem,
                    alt: "",
                    title: ""
                }
                });
            this.images = _images
            console.log('images')
            console.log(this.images)
            this.viewerVisible = true;
        }
    },
    created() {
        this.$http
            .get(this.$apiUrl + "/institucional/telas/eventosinternos")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.eventosinternos = retornoAPI.filter(x => x.visivel  && new Date(x.data1) >= new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000)).sort(function (a, b) {
                    if (a.data1 > b.data1) {
                        return 1;
                    }
                    if (a.data1 < b.data1) {
                        return -1;
                    }
                    return 0;
                }).reverse();

                this.myArray = this.eventosinternos
            });
    },
}

</script>

<style scoped>@import '/css_cart/carrinho.css';
@import '/css_cart/minha_conta.css';</style>