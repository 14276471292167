<template>
  <header class="main_menu home_menu">
    <section v-if="cabecalho != ''" style="background-color: #d3d3d3;padding-top: 3px;padding-bottom: 4px;">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-right" style="padding-right: 30px;">
            <a href="#" style="color:#000000;font-size: 13px;">{{ cabecalho[0].nome }}<img src="/img/iconWhats.png"
                style="width: 20px;padding-left: 5px;" /></a>
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <b-navbar toggleable="lg" class="navbar-expand-lg navbar-light site-navbar-target">
            <b-navbar-brand>
              <router-link to="/" class="navbar-brand">
                <img src="/img/logo.png" alt="Logo" class="img-thumbnail" style="border: 0px;" />
              </router-link>
              <b-navbar-toggle target="nav-collapse"><i class="fa fa-bars"></i></b-navbar-toggle>
            </b-navbar-brand>
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="navbar-nav nav ml-auto">
                <b-nav-item exact class="nav-link" to="/"><span>Home</span></b-nav-item>
                <b-nav-item class="nav-link" to="/sobre"><span>Sobre</span></b-nav-item>
                <b-nav-item class="nav-link" to="/fotos" style="display: none;"><span>Fotos</span></b-nav-item>
                <b-nav-item class="nav-link" to="/videos" style="display: none;"><span>Vídeos</span></b-nav-item>
                <b-nav-item class="nav-link" to="/eventos" style="display: none;"><span>Eventos</span></b-nav-item>
                <b-nav-item class="nav-link" to="/acoes" style="display: none;"><span>Ações</span></b-nav-item>
                <b-nav-item class="nav-link" to="/blog"><span>Blog</span></b-nav-item>
                <b-nav-item class="nav-link" to="/contato"><span>Contato</span></b-nav-item>
                <b-nav-item v-if="usuario == ''" class="nav-link" to="/login" 
                  style="background-color: rgb(204, 153, 51);padding: 4px 12px 4px 12px !important;border-radius: 6px;display: none;"><span
                    style="color:#ffffff;">Login</span></b-nav-item>
                <b-nav-item v-if="usuario != ''" class="nav-link" @click="Logout()"
                  style="background-color: rgb(204, 153, 51);padding: 4px 12px 4px 12px !important;border-radius: 6px;"><span
                    style="color:#ffffff;">Deslogar</span></b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// import FiltraImoveis from './FiltraImoveis.vue'

export default {
  //props: ['topoFundo','topoCor'],
  components: {
    // FiltraImoveis
  },
  data() {
    return {
      socialFacebook: [],
      facebookLink: '',

      socialInstagram: [],
      instagramLink: '',

      socialYoutube: [],
      youtubeLink: '',

      socialTwitter: [],
      twitterLink: '',

      cabecalho: [],
      usuario: {},
    }

  },
  methods: {
    CheckLogin() {
      let usuarioTmp = {
        email: '',
        senha: '',
      }
      usuarioTmp = JSON.parse(this.$cookie.get(this.$DadosCookie));
      if (usuarioTmp != null) {
        let _usuario = {
          email: usuarioTmp.email,
          senha: usuarioTmp.senha,
        };
        this.$http.post(this.$apiUrl + "/clientes/login", _usuario).then(
          (response) => {
            // get body data
            this.usuario = response.body;
          },
          (response2) => {
            // error callback
            this.$mensagem_erro(response2.body);

          }
        );
      }
      else {
        this.usuario = '';
      }
    },
    Logout() {
      this.$cookie.delete(this.$DadosCookie);
      localStorage.removeItem(this.$Dadoslocal);
      this.$redirect_reload("/");
    },
  },
  created() {
    this.CheckLogin();
    this.$http
      .get(this.$apiUrl + "/institucional/telas/cabecalho")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.cabecalho = retornoAPI

      });

  }

}
</script>

<style>
.nav-link {
  display: block;
  padding: 4px 10px 0px 6px !important;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
</style>