<template>

  <div>
    <Header />
    <section class="banner_part">
      <b-carousel id="carousel-1" v-model="slide" :interval="6000" controls indicators fade img-width="1024" img-height="480"
        class="slider-height d-flex align-items-center">
        <!-- Slides with custom text -->
        <b-carousel-slide v-for="slider in sliders" :key="slider.institucionalId"
          :img-src="$apiUrlIndex + slider.institucionalImg[0].urlImagem">
    
          <div class="container">
            <div class="row">
              <div class="col-lg-7 offset-lg-1 col-sm-8 offset-sm-2">
                <div class="banner_text aling-items-center">
                  <div class="banner_text_iner">
                    <h2>{{slider.nome}}</h2>
                    <p class="hidden-xs hidden-sm" v-html="slider.resumo1"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-carousel-slide>
      </b-carousel>
    </section>

    <!--::team part end::-->
   <section class="about_part section-padding">
      <div class="container">
         <div class="row">
            <div class="section_tittle">
               <h2>{{chamadaHomeSobre}}</h2>
            </div>
         </div>
         <div class="row">
            <div class="col-lg-6 col-md-6">
               <div class="about_img">
                  <img :src="imgHomeSobre" alt="" style="border-radius: 15px;">
               </div>
            </div>
            <div class="offset-lg-1 col-lg-5 col-sm-8 col-md-6">
               <div class="about_text">
                  <h2 v-html="tituloHomeSobre"></h2>
                  <p v-html="textoHomeSobre"></p>
                  <a :href="linkBtnHomeSobre" class="btn_1">{{txtBtnHomeSobre}}</a>
                  <div v-if="numeros!= '' && numeros!=null" class="about_part_counter">
                     <div v-for="num in numeros" :key="num.institucionalId" class="single_counter">
                        <strong><VueJsCounter start="0" :end="num.referencia" duration="8000" class="counter" style="font-size: 50px;font-weight: 300;line-height: 45px;"></VueJsCounter></strong>
                         <p>{{ num.nome }}</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!--::team part end::-->

   <!--::project part start::-->
   <section class="portfolio_area pt_30 padding_bottom" style="display:none;">
      <div class="container">
         <div class="row">
            <div class="col-lg-12">
               <div class="section_tittle">
                  <h2>{{tituloItens}}</h2>
               </div>
               <div class="portfolio-filter">
                  <h2 v-html="textoItens"></h2>
               </div>
               <div class="portfolio_item">
                  <div class="row align-items-center justify-content-between">
                     <div v-for="item in itensHome.slice(0,1)" :key="item.institucionalId" class="col-lg-6 col-sm-12 col-md-6">
                        <div class="portfolio_box">
                           <a v-if="item.institucionalImg!=''" :href="item.linkBtn1" class="img-gal">
                              <div class="single_portfolio">
                                 <img class="img-fluid w-100" :src="$apiUrlIndex + item.institucionalImg[0].urlImagem" alt="">
                              </div>
                           </a>
                           <div class="short_info">
                              <p>{{item.nome}}</p>
                              <h4>{{item.referencia}}</h4>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-5 col-md-6">
                        <div class="row">
                           <div v-for="item2 in itensHome.slice(1,3)" :key="item2.institucionalId" class="col-lg-12 col-sm-6 col-md-12 single_portfolio_project">
                              <div class="portfolio_box">
                                 <a v-if="item2.institucionalImg!=''" :href="item2.linkBtn1" class="img-gal">
                                    <div class="single_portfolio">
                                       <img class="img-fluid w-100" :src="$apiUrlIndex + item2.institucionalImg[0].urlImagem" alt="">
                                    </div>
                                 </a>
                                 <div class="short_info">
                                    <p>{{item2.nome}}</p>
                                    <h4>{{item2.referencia}}</h4>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

         </div>
      </div>
      </div>
   </section>
   <!--::project part end::-->

   <Depoimentos style="display:none;" />

   <section class="portfolio_area pt_30 padding_bottom" style="padding-top: 60px;display: none;">
      <div class="container">
         <div class="row" style="padding-bottom: 60px;">
            <div class="col-lg-12">
               <div class="section_tittle">
                  <h2>{{tituloVideo}}</h2>
               </div>
               <div class="video_text">
                  <h2 v-html="textoVideo"></h2>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-lg-12 col-md-12" style="text-align: center;">
               <iframe width="80%" height="400" :src="linkVideo" title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
            </div>
         </div>
      </div>
   </section>


   <div class="blog_part padding_bottom" style="background-color: #eff0e4;padding: 60px;">
      <div class="container">
         <div class="row">
            <div class="col-md-7 col-lg-5">
               <div class="blog_part_tittle">
                  <h2>Blog</h2>
               </div>
            </div>
         </div>
         <div class="row">
            <div v-for="blog1 in blog.slice(0,1)" :key="blog1.institucionalId" class="col-lg-7">
               <div class="single_blog">
                  <div class="appartment_img">
                     <img :src="$apiUrlIndex + blog1.institucionalImg[0].urlImagem" style="width: 100%;" alt="">
                  </div>
                  <div class="single_appartment_content">
                     <a href="#" @click="$redirect_reload('/blog/' + blog1.institucionalId + '/' + blog1.nome.replaceAll(' ','-').replaceAll('/',''))" style="color:#000000">{{ currentDateTime(blog1.data1)}}</a>
                     <a href="#" @click="$redirect_reload('/blog/' + blog1.institucionalId + '/' + blog1.nome.replaceAll(' ','-').replaceAll('/',''))">
                        <h4>{{blog1.nome}}</h4>
                     </a>
                  </div>
               </div>
            </div>
            <div class="col-lg-5">
               <div class="right_single_blog">
                  <div v-for="blog2 in blog.slice(1,3)" :key="blog2.institucionalId" class="single_blog" style="width: 100%;">
                     <div class="media">
                        <div class="media-body align-self-center">
                           <a href="#" @click="$redirect_reload('/blog/' + blog2.institucionalId + '/' + blog2.nome.replaceAll(' ','-').replaceAll('/',''))" style="color:#000000">{{ currentDateTime(blog2.data1) }}</a>
                           <a href="#" @click="$redirect_reload('/blog/' + blog2.institucionalId + '/' + blog2.nome.replaceAll(' ','-').replaceAll('/',''))">
                              <h5 class="mt-0">{{blog2.nome}}</h5>
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

  </div>
</template>

<script>
import Header from '../shared/Header.vue'
//import { Carousel, Slide } from 'vue-carousel';
import VueJsCounter from 'vue-js-counter'
import Depoimentos from '../shared/Depoimentos.vue';
import moment from 'moment'

export default {
  components: {
    Header,
    //FiltraImoveis,
    //Depoimentos,
    VueJsCounter
    //Carousel,
    //Slide
    ,
    Depoimentos
},
  data() {
    return {
      sliders: [],
      sobre:[],
      chamadaHomeSobre:'',
      tituloHomeSobre:'',
      textoHomeSobre:'',
      imgHomeSobre:'',
      txtBtnHomeSobre:'',
      linkBtnHomeSobre:'',
      chamadaHome: [],
      itensHome:[],
      chamadaItens:[],
      tituloItens:'',
      textoItens:'',
      video:[],
      tituloVideo:'',
      textoVideo:'',
      linkVideo:'',
      numeros:[],
      blog:[],
    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/slider")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.sliders = retornoAPI

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/sobrehome")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.sobre = retornoAPI
      if (this.sobre != ''){
         this.chamadaHomeSobre = this.sobre[0].nome
         this.tituloHomeSobre = this.sobre[0].resumo1
         this.textoHomeSobre = this.sobre[0].descricao1
         this.txtBtnHomeSobre = this.sobre[0].textoBtn1
         this.linkBtnHomeSobre = this.sobre[0].linkBtn1
         this.imgHomeSobre = this.$apiUrlIndex + this.sobre[0].institucionalImg[0].urlImagem  
      }
    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/numeros")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.numeros = retornoAPI.filter((x) => x.visivel)

      this.numeros.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })
    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/itens")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.itensHome = retornoAPI.filter((x) => x.visivel)

      this.itensHome.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })
    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/chamadaitens")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.chamadaItens = retornoAPI
      if (this.chamadaItens != ''){
         this.tituloItens = this.chamadaItens[0].nome
         this.textoItens = this.chamadaItens[0].resumo1
      }
    });
    
    this.$http
    .get(this.$apiUrl + "/institucional/telas/video")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.video = retornoAPI
      if (this.video != ''){
         this.tituloVideo = this.video[0].nome
         this.textoVideo = this.video[0].resumo1
         this.linkVideo = this.video[0].linkBtn1
      }
    });

  this.$http
    .get(this.$apiUrl + "/institucional/telas/blog")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.blog = retornoAPI.filter((x) => x.visivel)

      this.blog.sort(function (a, b) {
        if (a.data > b.data) { return 1; }       
        if (a.data < b.data) { return -1; }        
        return 0;
      }).reverse()
    });

  },
  methods: {
   currentDateTime(vdate) {
      return moment(vdate).format('DD/MM/YYYY')
    }
  }
  
}

</script>
<style>
.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    top: 20%;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}

@media (max-width: 576px) {
  .carousel-caption {
    top: 5%;
}
}

.about_part .about_text h2 p {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.6;
    margin-bottom: 37px;
    position: relative;
    padding-right: 20px;
}

.portfolio_area .portfolio-filter h2 p {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 40px;
}

.video_text h2 p {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 40px;
}

</style>


