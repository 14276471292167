<template>
<div>
    <Header />
    <section class="carrinho">

        <section class="main-minha_conta">
            <CheckAcesso />
            <div class="container">
                <div class="row">
                    <div class="col-lg-3" style="background-color: #cccccc;padding: 20px;">
                        <ul class="lista-sidebar_links">
                            <h5>Geral</h5>
                                <li><router-link to="/meusDados" class="texto active">Meus Dados</router-link></li>
                                <li><router-link to="/mural" class="texto">Mural Recados</router-link></li>
                                <li><router-link to="/contatosInterno" class="texto">Contatos</router-link></li>
                                <hr>
                                <h5>Informações</h5>
                                <li><router-link to="/atas" class="texto">Atas</router-link></li>
                                <li><router-link to="/contratos" class="texto">Contratos</router-link></li>
                                <li><router-link to="/estatuto" class="texto">Estatuto</router-link></li>
                                <hr>
                                <h5>Financeiro</h5>
                                <li><router-link to="/extratos" class="texto">Extratos</router-link></li>
                                <li><router-link to="/informativos" class="texto">Informativos</router-link></li>
                                <hr>
                                <h5>Comunicação</h5>
                                <li><router-link to="/eventosInterno" class="texto">Eventos</router-link></li>
                                <li><router-link to="/acoesInterno" class="texto">Ações</router-link></li>
                                <hr>
                        </ul>
                    </div>
                    <div class="col-lg-8 mt-5 mt-lg-0">
                        <h3 class="titulo-principal text-center mb-4">Meu acesso</h3>
                        <h6 class="subtitulo h5 mb-4">Meus Dados</h6>

                        <!-- Form editar dados -->
                        <form>
                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <small>Nome</small>
                                    <input v-model="nome" type="text" class="form-control" placeholder="Nome Completo">
                                </div>
                                <div class="col-md-6 mb-3">
                                    <small>Whatsapp</small>
                                    <input v-model="whatsapp" v-mask="'(##) #####-####'" type="text" class="form-control" placeholder="Whatsapp">
                                </div>
                                <div class="col-md-6 mb-3">
                                    <small>E-mail</small>
                                    <input v-model="email" type="text" class="form-control" placeholder="E-mail">
                                </div>
                                <div class="col-md-6 mb-3">
                                    <small>Torre</small>
                                    <input v-model="torre" type="text" class="form-control" placeholder="Torre">
                                </div>
                                <div class="col-md-6 mb-3">
                                    <small>Bloco</small>
                                    <input v-model="bloco" type="text" class="form-control" placeholder="Bloco">
                                </div>
                                <div class="col-md-6 mb-3">
                                    <small>Apartamento</small>
                                    <input v-model="apartamento" type="text" class="form-control" placeholder="Apartamento">
                                </div>
                                <div class="col-md-6 mb-3">
                                    <small>Senha</small>
                                    <input v-model="senha" type="password" class="form-control" placeholder="Senha">
                                </div>
                            </div>
                        </form>

                        <div class="text-right">
                            <button @click="AtualizaDados()" class="btn btn-success">Salvar Dados</button>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    </section>
</div>
</template>

<script>
import Header from '../shared/Header.vue'
    import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'

    export default {
        components: {
            CheckAcesso,
            Header
        },
        data() {
            return {
                ClienteId: 0,
                nome: '',
                torre: '',
                bloco: '',
                apartamento: '',
                whatsapp: '',
                email: '',
                senha: '',
                senhaatual: '',
                Cliente:[],
            }
        },
        methods: {
            AtualizaDados() {
            if (this.nome == '') {
                this.$mensagem_normal("Por favor preencha o nome completo");
            } else if (this.whatsapp == '') {
                this.$mensagem_normal("Por favor preencha o whatsapp");
            } else if (this.email == '') {
                this.$mensagem_normal("Por favor preencha o e-mail");
            } else if (this.torre == '') {
                this.$mensagem_normal("Por favor preencha a Torre");
            } else if (this.bloco == '') {
                this.$mensagem_normal("Por favor preencha o Bloco");
            } else if (this.apartamento == '') {
                this.$mensagem_normal("Por favor preencha o Apartamento");
                } else {
                    let _cliente =
                    {
                        clienteId: parseInt(this.ClienteId,10),
                        nomeEmpresa: this.nome,
                        email: this.email,
                        whatsapp: this.whatsapp,
                        telefone: this.telefone,
                        tipoCliente: { tipoClienteId: 1 },
                        ativo: true,
                        visivel: true,
                        senha: '',
                        campoExtra1: this.torre,
                        campoExtra2: this.bloco,
                        campoExtra3: this.apartamento,
                    };

                    if (this.senha != '') {
                        _cliente.senha = this.$sha1(this.senha);
                    }
                    else {
                        _cliente.senha = this.senhaatual;
                    }
                    

                    this.$http.put(this.$apiUrl + "/clientes/" + parseInt(this.ClienteId, 10), _cliente).then(
                        (response) => {
                            this.Cliente = response.body;
                            this.$mensagem_normal("Dados atualizados.");
                            this.senha = '';
                            this.$cookie.delete(this.$DadosCookie);
                            this.$redirect_reload("/login");
                        });
                }
            },
        },
        created() {
            let usuarioTmp = JSON.parse(this.$cookie.get(this.$DadosCookie));
            if (usuarioTmp != null) {
                this.ClienteId = parseInt(usuarioTmp.usuarioId, 10)

                this.$http
                    .get(this.$apiUrl + "/clientes/" + parseInt(this.ClienteId,10))
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.Cliente = tiu2;

                        this.nome = this.Cliente.nomeEmpresa
                        this.telefone = this.Cliente.telefone
                        this.whatsapp = this.Cliente.whatsapp
                        this.email = this.Cliente.email
                        this.senha = ''
                        this.senhaatual = this.Cliente.senha
                        this.torre = this.Cliente.campoExtra1
                        this.bloco = this.Cliente.campoExtra2
                        this.apartamento = this.Cliente.campoExtra3
                    });
            }

        }
    }

</script>

<style scoped>

    @import '/css_cart/carrinho.css';
    @import '/css_cart/minha_conta.css';
</style>