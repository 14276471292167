<template>

  <div>
    <Header />

   <!--::breadcrumb part start::-->
   <section class="breadcrumb" :style="{ backgroundImage: 'url('+ topo_bg + ')', height:'300px', backgroundRepeat:'no-repeat', backgroundSize: 'cover' }">
      <div class="container">
         <div class="row">
            <div class="col-lg-12">
               <div class="breadcrumb_iner">
                  <div class="breadcrumb_iner_item">
                     <h2>{{topoTitulo}}</h2>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!--::breadcrumb part start::-->

 <!--::team part end::-->
 <section class="about_part" style="padding-top: 60px;">
      <div class="container">
         <div class="row">
            <div class="section_tittle">
               <h2>{{topoTitulo}}</h2>
            </div>
         </div>
      </div>
   </section>
   <!--::team part end::-->


  <!--================Blog Area =================-->
  <section class="blog_area" style="padding-bottom: 60px;">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="blog_right_sidebar">
            <aside class="single_sidebar_widget post_category_widget">
              <h4 class="widget_title"></h4>
              <ul class="list cat-list">
                <li v-for="lgpd in InfLgpd" :key="lgpd.institucionalId">
                  <a href="#" @click.prevent="carregaTexto(lgpd)" class="d-flex">
                    <p>{{lgpd.nome}}</p>
                  </a>
                </li>
              </ul>
            </aside>
          </div>
        </div>
        <div class="col-lg-8 mb-5 mb-lg-0">
          <div class="blog_left_sidebar">
            <article class="blog_item">
              <div class="blog_details">
                <a class="d-inline-block" href="">
                  <h2>{{ Titulo }}</h2>
                </a>
                <p v-html="Texto"></p>
              </div>
            </article>
          </div>
        </div>

      </div>
    </div>
  </section>
  <!--================Blog Area =================-->
  </div>
</template>

<script>
import Header from '../shared/Header.vue'
// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    Header
  },
  data() {
    return {
      topoTitulo:'',
      textoTitulo:'',
      topo_bg:'',
     ValueId: this.$route.params.id,
     InfLgpd: [],
     Titulo:'',
     Texto:'',
    }
  },
  created() {


    this.$http
    .get(this.$apiUrl + "/institucional/telas/informaçõestopo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome
      this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/informações")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.InfLgpd = retornoAPI.filter((x) => x.visivel)

        this.InfLgpd.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })

        if (this.ValueId!=''){
          let info = this.InfLgpd.filter(x => x.institucionalId == parseInt(this.ValueId,10))
          this.carregaTexto(info[0]);
        }

      });
  },
  methods: {
    carregaTexto(value){
     this.Titulo = value.nome
     this.Texto = value.resumo1
    }
  }

}

</script>

