<template>
    <div>
        <Header />
        <section class="carrinho">

            <section class="main-minha_conta">
                <CheckAcesso />
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3" style="background-color: #cccccc;padding: 20px;">
                            <ul class="lista-sidebar_links">
                                <h5>Geral</h5>
                                <li><router-link to="/meusDados" class="texto">Meus Dados</router-link></li>
                                <li><router-link to="/mural" class="texto">Mural Recados</router-link></li>
                                <li><router-link to="/contatosInterno" class="texto">Contatos</router-link></li>
                                <hr>
                                <h5>Informações</h5>
                                <li><router-link to="/atas" class="texto">Atas</router-link></li>
                                <li><router-link to="/contratos" class="texto">Contratos</router-link></li>
                                <li><router-link to="/estatuto" class="texto">Estatuto</router-link></li>
                                <hr>
                                <h5>Financeiro</h5>
                                <li><router-link to="/extratos" class="texto active">Extratos</router-link></li>
                                <li><router-link to="/informativos" class="texto">Informativos</router-link></li>
                                <hr>
                                <h5>Comunicação</h5>
                                <li><router-link to="/eventosInterno" class="texto">Eventos</router-link></li>
                                <li><router-link to="/acoesInterno" class="texto">Ações</router-link></li>
                                <hr>
                            </ul>
                        </div>
                        <div class="col-lg-8 mt-5 mt-lg-0">
                            <h3 class="titulo-principal text-center mb-4">Extratos Financeiros</h3>
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <small>Ano</small>
                                        <select v-model="selectedYear" class="form-control">
                                            <option v-for="(year, index) in years" :value="year" :key="index">{{ year }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-4">
                                        <small>Mês</small>
                                        <select v-model="selectedMonth" class="form-control">
                                            <option v-for="(month, index) in months" :value="month" :key="index">{{ month }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2" style="padding-top: 24px">
                                        <button @click="filterArray" style="background-color: rgb(204, 153, 51);padding-right: 20px !important;padding-bottom: 7px !important;padding-left: 20px !important; padding-top: 7px;border-radius: 6px; color: rgb(255, 255, 255); border: none;">Filtrar</button>
                                    </div>
                                    <div class="col-lg-2" style="padding-top: 24px">
                                        <button @click="cleanArray" style="background-color: #3394cc;padding-right: 20px !important;padding-bottom: 7px !important;padding-left: 20px !important; padding-top: 7px;border-radius: 6px; color: rgb(255, 255, 255); border: none;">Limpar</button>
                                    </div>
                                    <div class="col-lg-4 mt-4 mb-4" style="text-align: center;padding-top: 30px;" v-for="item in myArray"
                                        :key="item.institucionalId">
                                        <a :href="item.campoExtra2" target="_blank">
                                            <img src="img/pdfimg.jpg" title="" alt=""
                                                style="width: 50px; padding-bottom: 10px;" /><br>
                                            <strong style="color:#000000;">{{ item.nome }} </strong><br>
                                            <div style="color:#000000;">Data Publicação: {{ FormatDateTime(item.data1) }}</div>
                                        </a>
                                        
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </div>
</template>

<script>
import Header from '../shared/Header.vue'
import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'
import moment from 'moment';

export default {
    components: {
        CheckAcesso,
        Header
    },
    data() {
        return {
            selectedYear: null,
            selectedMonth: null,
            years: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], // exemplo de anos disponíveis
            months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'], // exemplo de meses disponíveis
            extratos: [],
            myArray: []
        }
    },
    methods: {
        currentDateTime(vdate) {
            if (vdate != null) {
                return moment(vdate).format('LL');
            }
            else {
                return '';
            }

        },
        FormatDateTime(vdate) {
            moment.locale("pt-br");
            return moment(vdate).format('DD/MM/YYYY')
        },
        filterArray() {
            const filteredArray = this.extratos.filter(item => {
                const itemDate = new Date(item.data1); // supondo que o objeto tem um atributo "date"
                return itemDate.getFullYear() === this.selectedYear && itemDate.getMonth() === this.months.indexOf(this.selectedMonth);
            });
            this.myArray = filteredArray; // faça o que quiser com o array filtrado
        },
        cleanArray() {
            this.myArray = this.extratos;
            this.selectedYear = null;
            this.selectedMonth = null;
        }
    },
    created() {
        this.$http
            .get(this.$apiUrl + "/institucional/telas/extratosfinanceiros")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.extratos = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                    if (a.data1 > b.data1) {
                        return 1;
                    }
                    if (a.data1 < b.data1) {
                        return -1;
                    }
                    return 0;
                }).reverse();

                this.myArray = this.extratos
            });
    }
}

</script>

<style scoped>@import '/css_cart/carrinho.css';
@import '/css_cart/minha_conta.css';</style>