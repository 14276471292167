<template>
    <div>
        <Header />
        <section class="main-carrinho">

            <section class=""
                :style="{ backgroundImage: 'url(' + $apiUrlIndex + fundotela + ')', backgroundColor: '#e8eaf6', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '405px' }">
                <div>
                    <div class="container">
                        <div class="row py-5">
                            <div class="col-lg-7 hidden-sm-md">
                                <div class="carrinho-bg set-img">

                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="box_content">
                                    <h3 v-if="Titulos != ''" class="titulo-principal text-center mb-5">{{ Titulos[0].nome }}
                                    </h3>
                                    <div class="input-group mb-3">
                                        <input v-model="Formulario.Email" type="text"
                                            class="form-control form-control-icone" placeholder="Email"
                                            onfocus="this.placeholder=''" onblur="this.placeholder='Email'">
                                        <div class="input-group-append">
                                            <span class="input-group-text input-icone" id="#">
                                                <i class="fas fa-envelope"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="AbreSenha || AbreCad" class="input-group mb-3">
                                        <input v-model="Formulario.Senha" type="password"
                                            class="form-control form-control-icone" placeholder="Senha"
                                            onfocus="this.placeholder=''" onblur="this.placeholder='Senha'">
                                        <div class="input-group-append">
                                            <span class="input-group-text input-icone" id="#">
                                                <i class="fas fa-lock"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="AbreCad" class="input-group mb-3">
                                        <input v-model="Formulario.Nomecli" type="text"
                                            class="form-control form-control-icone" placeholder="Nome Completo*"
                                            onfocus="this.placeholder=''" onblur="this.placeholder='Nome Completo*'">
                                        <div class="input-group-append">
                                            <span class="input-group-text input-icone" id="#">
                                                <i class="fas fa-user-circle"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="AbreCad" class="input-group mb-3">
                                        <input v-model="Formulario.Whatscli" type="text"
                                            class="form-control form-control-icone" placeholder="Whatsapp*"
                                            onfocus="this.placeholder=''" onblur="this.placeholder='Whatsapp*'">
                                        <div class="input-group-append">
                                            <span class="input-group-text input-icone" id="#">
                                                <i class="fas fa-phone"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="AbreCad" class="input-group mb-3">
                                        <input v-model="Formulario.Torre" type="text"
                                            class="form-control form-control-icone" placeholder="Torre*"
                                            onfocus="this.placeholder=''" onblur="this.placeholder='Torre*'">
                                        <div class="input-group-append">
                                            <span class="input-group-text input-icone" id="#">
                                                <i class="fas fa-user-circle"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="AbreCad" class="input-group mb-3">
                                        <input v-model="Formulario.Bloco" type="text"
                                            class="form-control form-control-icone" placeholder="Bloco*"
                                            onfocus="this.placeholder=''" onblur="this.placeholder='Bloco*'">
                                        <div class="input-group-append">
                                            <span class="input-group-text input-icone" id="#">
                                                <i class="fas fa-user-circle"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="AbreCad" class="input-group mb-3">
                                        <input v-model="Formulario.Apartamento" type="text"
                                            class="form-control form-control-icone" placeholder="Apartamento*"
                                            onfocus="this.placeholder=''" onblur="this.placeholder='Apartamento*'">
                                        <div class="input-group-append">
                                            <span class="input-group-text input-icone" id="#">
                                                <i class="fas fa-user-circle"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <a href="#" v-if="AbreSenha" class="navbar_detalhes-link" @click="showModal_senha = true">
                                        Esqueci a senha
                                    </a>
                                    <!-- <button class="btn btn-success w-100 mt-4">Fazer Login</button> -->
                                    <button v-if="!AbreSenha && !AbreCad" @click="ValidaCliente()"
                                        class="btn btn-success w-100 mt-4">Acessar/Cadastrar</button>
                                    <button v-if="AbreSenha" @click="entrar()"
                                        class="btn btn-success w-100 mt-4">Acessar</button>
                                    <button v-if="AbreCad" @click="carregaCliente()"
                                        class="btn btn-success w-100 mt-4">Cadastrar</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Modal esqueci a senha -->
            <Modal v-model="showModal_senha">

                <section class="modal-senha">
                    <div class="container">
                        <h3 class="titulo-principal text-center">Esqueci a Senha</h3>
                        <div class="row">
                            <div class="col">
                                <form action="#" class="p-4 p-lg-5">
                                    <div class="input-group mb-3">
                                        <input type="text" v-model="emailreset" class="form-control form-control-icone"
                                            placeholder="Email" onfocus="this.placeholder=''"
                                            onblur="this.placeholder='Email'">
                                        <div class="input-group-append">
                                            <span class="input-group-text input-icone" id="#">
                                                <i class="fas fa-envelope"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <a @click="EnviarResetSenha()" class="btn btn-success w-100">Recuperar a senha</a>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

            </Modal>
        </section>
    </div>
</template>

<script>
import Header from '../shared/Header.vue'
import VueModal from '@kouts/vue-modal'
import moment from 'moment';


export default {
    components: {
        'Modal': VueModal,
        Header
    },
    data() {
        return {
            Titulos: {},
            Formulario: {
                Email: '',
                Torre: '',
                Bloco: '',
                Apartamento: '',
                Senha: '',
                Nomecli: '',
                Whatscli: '',
            },
            showModal_senha: false,
            AbreSenha: false,
            AbreCad: false,
            Clientes: [],
            ClientesGeral: [],
            Cliente: [],
            fundotela: '',
            DadosCompra: {
                produtos: [],
                mensagem: [],
                agenda: [],
                foto: [],
                avulsos: [],
                baloes: [],
                informacao: [],
                pagamento: [],
                cupom: [],
            },
            ClientesGeralReset: [],
            Contato: {
                NomeEmpresa: "Central Parque Poa ",
                Assunto: "",
                Destinatario: "",
                EmailResposta: "",
                TextoEmail: ""
            },
            contatoEmail: '',
            emailreset:'',
        }
    },
    created() {
        this.CheckLogin();
        //----------------- Titulos planos
        this.$http
            .get(this.$apiUrl + "/institucional/telas/textologin")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.Titulos = retornoAPI;
                console.log('textos login')
                console.log(this.Titulos)
                if (this.Titulos[0].institucionalImg != '') {
                    this.fundotela = this.Titulos[0].institucionalImg[0].urlImagem
                }
                else {
                    this.fundotela = '';
                }
            });

        this.$http
            .get(this.$apiUrl + "/institucional/telas/contato")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.contato = retornoAPI
                if (this.contato != '') {
                    this.contatoEmail = this.contato[0].referencia
                }
            });
    },
    methods: {
        EnviarResetSenha() {
            if (this.emailreset == '') {
                this.$mensagem_normal("Por favor preencha o e-mail da conta.");
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/clientes")
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.ClientesGeralReset = tiu2.filter(x => x.email.toLowerCase().trim() == this.emailreset.toLowerCase().trim());
                        //console.log(this.ClientesGeralReset)
                        if (this.ClientesGeralReset != '') {
                            if (this.ClientesGeralReset[0].ativo) {
                                //emailrecuperasenha
                                let objetoreset = {
                                    clienteid: parseInt(this.ClientesGeralReset[0].clienteId, 10),
                                    site: 'centralparque.com.br',
                                    texto: 'Sua solicita&Ccedil;&atilde;o de altera&Ccedil;&atilde;o de senha foi efetuada.',
                                };
                                this.$http.post(this.$apiUrl + "/clientes/emailrecuperasenha", objetoreset).then(
                                    (response) => {
                                        // get body data
                                        objetoreset = response.body;
                                        this.$mensagem_normal("Senha enviada para seu e-mail.");
                                        this.$cookie.delete(this.$DadosCookie);
                                    },
                                    (response) => {
                                        // error callback
                                        this.$mensagem_erro(response.body);
                                    }
                                );
                            }
                            else {
                                this.$mensagem_normal("Usuário já Cadastrado! Aguarde validação e liberação Adminstrativa da Central Parque.");
                            }
                        }
                        else {
                            this.$mensagem_normal("Usuário não cadastrado no sistema.");
                        }
                    });
            }
        },
        ValidaCliente() {
            if (this.Formulario.Email == '') {
                this.$mensagem_normal("Por favor preencha o e-mail");
            }
            else {
                if (!this.Abresenha) {
                    this.$http
                        .get(this.$apiUrl + "/clientes")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.ClientesGeral = tiu2;
                            this.Clientes = tiu2.filter(x => x.email.toLowerCase().trim() == this.Formulario.Email.toLowerCase().trim());

                            if (this.Clientes != '') {
                                if (this.Clientes[0].ativo) {
                                    this.$mensagem_normal("Usuario ja cadastrado, informe sua senha.");
                                    this.AbreSenha = true;
                                    this.AbreCad = false;
                                }
                                else {
                                    this.$mensagem_normal("Usuário já Cadastrado! Aguarde validação e liberação Adminstrativa da Central Parque.");
                                }
                            }
                            else {
                                this.$mensagem_normal("Usuario novo, informe alguns dados.");
                                this.AbreCad = true;
                                this.AbreSenha = false;
                            }

                        });
                }
            }
        },
        entrar() {
            let testacli = this.ClientesGeral.filter(x => x.email.toLowerCase().trim() == this.Formulario.Email.toLowerCase().trim());

            if (testacli == '') {
                this.$mensagem_normal("Usuario novo, informe alguns dados.");
                this.AbreCad = true;
                this.AbreSenha = false;
            }
            else {
                if (!testacli[0].ativo) {
                    this.$mensagem_normal("Aguarde validação e liberação Adminstrativa da Central Parque.");
                }
                else {
                    let _usuario = {
                        email: this.Formulario.Email,
                        senha: this.$sha1(this.Formulario.Senha),
                    };
                    let usuario = {};
                    this.$http.post(this.$apiUrl + "/clientes/login", _usuario).then(
                        (response) => {
                            // get body data
                            usuario = response.body;
                            let _dados = {
                                nomeUsuario: '',
                                usuarioId: '',
                                senha: '',
                                email: '',
                            }
                            _dados.nomeUsuario = usuario.nomeEmpresa;
                            _dados.usuarioId = usuario.clienteId;
                            _dados.senha = usuario.senha;
                            _dados.email = usuario.email;

                            this.$cookie.set(this.$DadosCookie, JSON.stringify(_dados), {
                                expires: "120m",
                            });

                            let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                            if (!dadosArmazenados) {
                                dadosArmazenados = this.DadosCompra;
                                localStorage.setItem(this.$Dadoslocal, JSON.stringify(dadosArmazenados))
                                this.$redirect("/mural");
                            } else {
                                this.$redirect("/mural");
                            }
                        },
                        (response) => {
                            // error callback
                            this.$cookie.delete(this.$DadosCookie);
                            this.$mensagem_erro(response.body);
                        }
                    );
                }
            }
        },
        CheckLogin() {
            let usuarioTmp = {
                email: '',
                senha: '',
            }
            usuarioTmp = JSON.parse(this.$cookie.get(this.$DadosCookie));
            if (usuarioTmp != null) {
                let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                if (!dadosArmazenados) {
                    dadosArmazenados = this.DadosCompra;
                    localStorage.setItem(this.$Dadoslocal, JSON.stringify(dadosArmazenados))
                    this.$redirect("/mural");
                } else {
                    this.$redirect("/mural");
                }
            }
        },
        carregaCliente() {
            if (this.Formulario.Nomecli == '') {
                this.$mensagem_normal("Por favor preencha o nome completo");
            } else if (this.Formulario.Whatscli == '') {
                this.$mensagem_normal("Por favor preencha o whatsapp");
            } else if (this.Formulario.Email == '') {
                this.$mensagem_normal("Por favor preencha o e-mail");
            } else if (this.Formulario.Senha == '') {
                this.$mensagem_normal("Por favor preencha a Senha");
            } else if (this.Formulario.Torre == '') {
                this.$mensagem_normal("Por favor preencha a Torre");
            } else if (this.Formulario.Bloco == '') {
                this.$mensagem_normal("Por favor preencha o Bloco");
            } else if (this.Formulario.Apartamento == '') {
                this.$mensagem_normal("Por favor preencha o Apartamento");
            } else {

                let testacli = this.ClientesGeral.filter(x => x.email.toLowerCase().trim() == this.Formulario.Email.toLowerCase().trim());

                if (testacli != '') {
                    this.$mensagem_normal("Usuario ja cadastrado, informe sua senha.");
                    this.AbreSenha = true;
                    this.AbreCad = false;
                }
                else {
                    let _cliente =
                    {
                        clienteId: 0,
                        nomeEmpresa: this.Formulario.Nomecli,
                        email: this.Formulario.Email,
                        whatsapp: this.Formulario.Whatscli,
                        telefone: this.Formulario.Whatscli,
                        tipoCliente: { tipoClienteId: 1 },
                        ativo: false,
                        visivel: true,
                        DataCadastro: moment().format(),
                        senha: this.$sha1(this.Formulario.Senha),
                        campoExtra1: this.Formulario.Torre,
                        campoExtra2: this.Formulario.Bloco,
                        campoExtra3: this.Formulario.Apartamento,
                    };

                    this.$http.post(this.$apiUrl + "/clientes/", _cliente).then(
                        (response) => {
                            this.Cliente = response.body;
                            this.enviarDadosUsuario();
                            this.enviarDadosCentral();
                            this.$mensagem_normal("Cadastro efetuado com sucesso! Aguarde validação e liberação Adminstrativa da Central Parque.");
                            this.$redirect("/");
                        });
                }
            }
        },
        enviarDadosUsuario() {

            //Email que irá receber os dados do formulário preenchido (empresa site)
            this.Contato.Destinatario = this.Formulario.Email

            //Email de quem preencheu formulário e enviou email (usuário/cliente)
            this.Contato.EmailResposta = this.contatoEmail
            this.Contato.Assunto = "Cadastro no Site - Aguardando Liberação"

            // Dados que aparecerão no corpo do email
            this.Contato.TextoEmail =
                "Seu cadastro no site Central Parque foi efetuado. Em breve estaremos liberando seu acesso à area interna de informações. Notificaremos você por e-mail. <br/><br/> Att, <br/> <strong>Equipe Central Parque</strong>";

            this.$http.post(this.$apiUrl + "/contato/enviamail", this.Contato)
                .then(response => {
                    if (response.ok)
                        console.log('OK')
                }, error => {
                    console.log(error.bodyText)
                });
        },
        enviarDadosCentral() {

            //Email que irá receber os dados do formulário preenchido (empresa site)
            this.Contato.Destinatario = this.contatoEmail

            //Email de quem preencheu formulário e enviou email (usuário/cliente)
            this.Contato.EmailResposta = this.Formulario.Email
            this.Contato.Assunto = "Cadastro no Site - Liberar Usuario: " + this.Formulario.Nomecli

            // Dados que aparecerão no corpo do email
            this.Contato.TextoEmail =
                "Cadastro aguardando liberação <br/> <br/>Nome: " + this.Formulario.Nomecli + "<br/>Fone: " + this.Formulario.Whatscli + "<br/>E-mail: " + this.Formulario.Email + "<br/>Torre: " + this.Formulario.Torre + "<br/>Bloco: " + this.Formulario.Bloco + "<br/>Apartamento: " + this.Formulario.Apartamento;

            this.$http.post(this.$apiUrl + "/contato/enviamail", this.Contato)
                .then(response => {
                    if (response.ok)
                        console.log('OK')
                }, error => {
                    console.log(error.bodyText)
                });
        },
    }
}

</script>

<style scoped>
@import '/css_cart/carrinho.css';
@import '/css_cart/login.css';
</style>