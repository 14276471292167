<template>

  <div>
    <Header />
   <!--::breadcrumb part start::-->
   <section class="breadcrumb" :style="{ backgroundImage: 'url('+ topo_bg + ')', height:'300px', backgroundRepeat:'no-repeat', backgroundSize: 'cover' }">
      <div class="container">
         <div class="row">
            <div class="col-lg-12">
               <div class="breadcrumb_iner">
                  <div class="breadcrumb_iner_item">
                     <h2>{{topoTitulo}}</h2>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!--::breadcrumb part start::-->

   <!--::team part end::-->
   <section class="about_part section-padding">
      <div class="container">
         <div class="row">
            <div class="section_tittle">
               <h2>{{fotosTituloPreto}}</h2>
            </div>
         </div>
         <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12 mb-5">
               <div class="about_text">
                  <p v-html="fotosDescricao"></p>
               </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12" style="text-align: center;">
               <div class="images" v-viewer>
                  <img v-for="src in Galeria[0].institucionalImg" :src="$apiUrlIndex + src.urlImagem"
                :key="src.institucionalImgId" style="height: 200px;padding: 10px;border-radius: 15px;cursor: pointer;">
            </div>
            </div>
         </div>
      </div>
   </section>
   <!--::team part end::-->

   <Depoimentos />
  </div>
</template>

<script>
import Header from '../shared/Header.vue'
import Depoimentos from '../shared/Depoimentos.vue'
import 'viewerjs/dist/viewer.css'
export default {
  components: {
     Header,
     Depoimentos,
  },
  data() {
    return {
      topoInternas: [],
      topoTitulo: '',
      topo_bg: '',

      fotosTituloPreto: '',
      fotosDescricao: '',
      Galeria:[],
    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/fotostopo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome
      this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/fotosbairro")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.Galeria = retornoAPI

      this.fotosTituloPreto = this.Galeria[0].nome
      this.fotosDescricao = this.Galeria[0].resumo1

    });

  }
  
}

</script>

