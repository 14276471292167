<template>

  <div>
    <Header />
   <!--::breadcrumb part start::-->
   <section class="breadcrumb" :style="{ backgroundImage: 'url('+ topo_bg + ')', height:'300px', backgroundRepeat:'no-repeat', backgroundSize: 'cover' }">
      <div class="container">
         <div class="row">
            <div class="col-lg-12">
               <div class="breadcrumb_iner">
                  <div class="breadcrumb_iner_item">
                     <h2>{{topoTitulo}}</h2>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!--::breadcrumb part start::-->

   <!--::team part end::-->
   <section class="about_part section-padding">
      <div class="container">
         <div class="row">
            <div class="section_tittle">
               <h2>{{topoTitulo}}</h2>
            </div>
         </div>
         <div class="row">
            <div class="col-lg-6 col-md-6">
               <div class="about_img">
                  <img :src="sobreImg" alt="" style="border-radius: 15px;">
               </div>
            </div>
            <div class="offset-lg-1 col-lg-5 col-sm-8 col-md-6">
               <div class="about_text">
                  <h2 v-html="sobreTituloPreto"></h2>
                  <p v-html="sobreDescricao"></p>
                  <div class="about_part_counter">
                    <div v-if="numeros!= '' && numeros!=null" class="about_part_counter">
                     <div v-for="num in numeros" :key="num.institucionalId" class="single_counter">
                        <strong><VueJsCounter start="0" :end="num.referencia" duration="8000" class="counter" style="font-size: 50px;font-weight: 300;line-height: 45px;"></VueJsCounter></strong>
                         <p>{{ num.nome }}</p>
                     </div>
                  </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!--::team part end::-->

      <!--::team part end::-->
      <section class="about_part" style="padding-bottom: 60px;display: none;">
      <div class="container">
         <div class="row">
            <div class="section_tittle">
               <h2>{{diretoriaTitulo}}</h2>
            </div>
         </div>
         <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12">
               <div class="about_text">
                  <h2 v-html="diretoriaTituloPreto"></h2>
                  <p v-html="diretoriaDescricao"></p>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!--::team part end::-->

   <Depoimentos style="display: none;" />
  </div>
</template>

<script>
import Header from '../shared/Header.vue'
import Depoimentos from '../shared/Depoimentos.vue'
import VueJsCounter from 'vue-js-counter'
export default {
  components: {
     Header,
     Depoimentos,
    VueJsCounter
  },
  data() {
    return {
      topoInternas: [],
      topoTitulo: '',
      topo_bg: '',

      sobre: [],
      sobreImg: '',
      sobreTituloPreto: '',
      sobreDescricao: '',
      numeros:[],

      diretoria: [],
      diretoriaTitulo: '',
      diretoriaTituloPreto:'',
      diretoriaDescricao:'',

      CadastroParceiros:[],
      Missao:[],
      Bannerinferior:[],
      BannerPreto:'',
      BannerAzul:'',
      BannerDescricao:'',
      BannerTxtBtn:'',
      BannerLinkBtn:'',
      BannerImg:'',
    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/sobretopo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome
      this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/sobretexto")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.sobre = retornoAPI

      this.sobreTituloPreto = this.sobre[0].resumo1
      this.sobreDescricao = this.sobre[0].descricao1
      this.sobreImg = this.$apiUrlIndex + this.sobre[0].institucionalImg[0].urlImagem

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/numeros")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.numeros = retornoAPI.filter((x) => x.visivel)

      this.numeros.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })
    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/diretoria")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.diretoria = retornoAPI

      this.diretoriaTitulo = this.diretoria[0].nome
      this.diretoriaTituloPreto = this.diretoria[0].resumo1
      this.diretoriaDescricao = this.diretoria[0].descricao1

    });

  /* this.$http
    .get(this.$apiUrl + "/institucional/telas/cadastroparceiros")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.CadastroParceiros = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })
    });

     this.$http
    .get(this.$apiUrl + "/institucional/telas/missao")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.Missao = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })
    });

     this.$http
    .get(this.$apiUrl + "/institucional/telas/bannerinferior")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.Bannerinferior = retornoAPI

    if (this.Bannerinferior!= ''){
      this.BannerPreto = this.Bannerinferior[0].nome
      this.BannerAzul = this.Bannerinferior[0].referencia
      this.BannerDescricao = this.Bannerinferior[0].descricao1
      this.BannerTxtBtn = this.Bannerinferior[0].textoBtn1
      this.BannerLinkBtn = this.Bannerinferior[0].linkBtn1
      if (this.Bannerinferior[0].institucionalImg!= ''){
          this.BannerImg= this.$apiUrlIndex + this.Bannerinferior[0].institucionalImg[0].urlImagem;
      }
      
    }
     


    }); */




  }
  
}

</script>

