<template>

  <section v-if="Depoimentos!= ''" class="depoimentos about_part dep">
    <div class="container">
      <div class="row">
            <div class="section_tittle">
               <h2 style="margin-bottom: 20px !important;">Depoimentos</h2>
            </div>
         </div>
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <carousel class="pt-5" :autoplay="true" :responsive="{0:{items:1,nav:false},600:{items:1,nav:false}}">

            <div v-for="itemDep in Depoimentos" :key="itemDep.instuticionalId" class="text-center testimonial__text">
              <center><img src="/img/testimonial/quote.png" style="width: 67px;" alt=""></center>
              <p v-html="itemDep.resumo1"></p>
              <br />
              <br />
              <h6>{{itemDep.nome}}</h6>
              <span>{{itemDep.referencia}}</span>
              <center><img :src="$apiUrlIndex + itemDep.institucionalImg[0].urlImagem" style="width: 90px;" alt=""></center>
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  components: { 
    carousel 
  },
   data() {
            return {
                Depoimentos: [],
            }
        },
   created() {

            this.$http
                .get(this.$apiUrl + "/institucional/telas/depoimentos")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Depoimentos = tiu2;
                });

               
        },
}

</script>

<style scoped>

  .text_depo {
    color: #828282;
    font-weight: 400;
    font-style: italic;
  }

  .autor_depo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px auto;
    max-width: 220px;
  }

  .img-depo {
    width: 70px !important;
    height: 70px;
    border-radius: 50%;
  }

.dep{
  background-color: #eff0e4;
  padding: 60px;
}

@media (max-width:768px) {
  .dep{
  background-color: #eff0e4;
  padding: 10px;
  font-size: 15px;
}
}

@media (max-width: 991px){
.about_part .section_tittle h2 {
    padding-left: 175px;
    margin-bottom: 20px;
    font-size: 18px;
}
}


</style>