<template>

  <div>

    <Header />
    <!--::breadcrumb part start::-->
    <section class="breadcrumb"
      :style="{ backgroundImage: 'url('+ topo_bg + ')', height:'300px', backgroundRepeat:'no-repeat', backgroundSize: 'cover' }">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrumb_iner">
              <div class="breadcrumb_iner_item">
                <h2>{{topoTitulo}}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--::breadcrumb part start::-->
    <section class="about_part section-padding">
      <div class="container">
         <div class="row">
            <div class="section_tittle">
               <h2>{{artigo_titulo}}</h2>
            </div>
         </div>
         <div class="row">
          <div class="col-lg-12">
            <div class="blog__details__item normal__item">
              <div class="blog__details__item__text" style="text-align: justify;">
                <h4 style="padding-bottom: 20px;"><span>Dia {{ currentDateTime(artigo_data) }} {{  artigo_hora }} </span></h4>
                <div class="row">
                  <div class="col-lg-6">
                    <img :src="artigo_imagem" alt="" style="width: 100%;" />
                  </div>
                  <div class="col-lg-6">
                    <p v-html="artigo_texto"></p>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-12 text-center">
            <div class="view__all view__all--portfolio">
              <a href="" @click="$redirect('/Eventos')" style="color: #cc9933;font-weight: 500;">voltar</a>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import Header from '../shared/Header.vue'
// import Depoimentos from '../shared/Depoimentos.vue'
import moment from 'moment'

export default {
  components: {
    Header
  },
  data() {
    return {
      contato: [],
      topoTitulo: '',
      textoTitulo: '',

      artigo: [],
      artigo_titulo:'',
      artigo_texto:'',
      artigo_data:'',
      artigo_hora:'',
      artigo_imagem:'',
    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/eventostopo")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.topoInternas = retornoAPI

        this.topoTitulo = this.topoInternas[0].nome
        this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

      });

    if (typeof this.$route.params.id != 'undefined') {
      this.carregaArtigo(this.$route.params.id);
    }

  },
  methods: {
    carregaArtigo(idArtigo) {
      this.$http
        .get(this.$apiUrl + "/institucional/" + idArtigo)
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.artigo = retornoAPI
          this.artigo_titulo = this.artigo.nome
          this.artigo_texto = this.artigo.resumo1;
          this.artigo_data = this.artigo.data1;
          this.artigo_hora = this.artigo.referencia;

          if (this.artigo.institucionalImg[0] != null) {
            this.artigo_imagem = this.$apiUrlIndex + this.artigo.institucionalImg[0].urlImagem;
          }
          else {
            this.artigo_imagem = '';
          }

        });
    },
    currentDateTime(vdate) {
      return moment(vdate).format('DD/MM/YYYY')
    }
  }

}

</script>

<style>
.blog__item {
  -webkit-box-shadow: 0px 10px 21px rgb(99 133 136 / 16%);
  box-shadow: 0px 10px 21px rgb(99 133 136 / 16%);
  margin-bottom: 50px;
}

.blog__item__text {
  text-align: center;
  position: relative;
  padding: 38px 35px 30px;
}
</style>