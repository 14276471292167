<template>

   <!--::footer_part start::-->
   <footer class="footer_part" style="background-image: url('/img/08.png');background-repeat: no-repeat;background-size: contain;">
      <div class="container">
         <div class="row">
            <div class="col-lg-12">
               <div class="footer_logo">
                     <router-link to="/" class="footer_logo_iner"> <img src="/img/logo.png" alt=""/> </router-link>
               </div>
            </div>

            <div class="col-sm-6 col-lg-3">
               <div class="single_footer_part">
                  <h4>{{titulo}}</h4>
                  <p v-html="sobreRodape"></p>
                  <div class="footer_icon social_icon">
                     <ul class="list-unstyled">
                        <li v-for="rede in redes" :key="rede.institucionalId">
                            <a :href="rede.linkBtn1" target="_blank" class="single_social_icon"><i :class="'fab fa-' + rede.nome.toLowerCase()" style="font-size: 20px;"></i></a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="col-sm-6 col-lg-3">
               <div class="single_footer_part">
                  <h4>Contatos</h4>
                  <p v-html="Endereco"></p>
               </div>
            </div>
            <div class="col-sm-6 col-lg-3">
               <div class="single_footer_part">
                  <h4>Institucional</h4>
                  <ul class="list-unstyled">
                     <li><router-link to="/sobre">Sobre</router-link></li>
                     <li><router-link to="/fotos" style="display: none;">Fotos</router-link></li>
                     <li><router-link to="/videos" style="display: none;">Vídeos</router-link></li>
                     <li><router-link to="/eventos" style="display: none;">Eventos</router-link></li>
                     <li><router-link to="/acoes" style="display: none;">Ações</router-link></li>
                     <li><router-link to="/blog">Blog</router-link></li>
                     <li><router-link to="/contato">Contato</router-link></li>
                  </ul>
               </div>
            </div>
            <div class="col-sm-6 col-lg-3">
               <div class="single_footer_part">
                  <h4>Privacidade</h4>
                  <ul class="list-unstyled">
                     <li v-for="informacao in informacoes" :key="informacao.institucionalId">
                      <a href="#" @click="$redirect_reload('/informacoes/' + informacao.institucionalId + '/' + informacao.nome.replaceAll(' ','-').replaceAll('/',''))">{{informacao.nome}}</a></li>
                     <li><router-link to="/login" style="display: none;">Login</router-link></li>
                  </ul>
               </div>
            </div>
         </div>
         <hr>
         <div class="row">
            <div class="col-lg-12">
               <div class="copyright_text text-center">
                <div class="col text-center">
                <a href="https://avancedigital.com.br/" target="_blank"><img src="/img/logo_avance.png" alt=""></a>
              </div>
               </div>
            </div>
         </div>
      </div>
   </footer>
   <!--::footer_part end::-->

</template>


<script>
export default {
  data() {
    return {
      infoRodape: [],
      sobreRodape: '',
      Endereco: '',
      titulo: '',
      redes: [],
      informacoes:[],
    }
  },
  created() {

     this.$http
      .get(this.$apiUrl + "/institucional/telas/rodape")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.infoRodape = retornoAPI
        if (this.infoRodape != '') {
          this.titulo = this.infoRodape[0].nome
          this.sobreRodape = this.infoRodape[0].resumo1
          this.Endereco = this.infoRodape[0].descricao1
         
          this.Email = this.infoRodape[0].campoExtra1
          this.Link = this.infoRodape[0].referencia
        }
      });

  this.$http
      .get(this.$apiUrl + "/institucional/telas/redessociais")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.redes = retornoAPI.filter((x) => x.visivel)

        this.redes.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })

      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/informacoes")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.informacoes = retornoAPI.filter((x) => x.visivel)

        this.informacoes.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })

      });

  },
  methods: {
  }
}
</script>

<style>
a {
  text-decoration: none !important;
}
</style>