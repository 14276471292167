<template>

  <div>

    <Header />
   <!--::breadcrumb part start::-->
   <section class="breadcrumb" :style="{ backgroundImage: 'url('+ topo_bg + ')', height:'300px', backgroundRepeat:'no-repeat', backgroundSize: 'cover' }">
      <div class="container">
         <div class="row">
            <div class="col-lg-12">
               <div class="breadcrumb_iner">
                  <div class="breadcrumb_iner_item">
                     <h2>{{topoTitulo}}</h2>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!--::breadcrumb part start::-->

      <!--::team part end::-->
      <section class="about_part" style="padding-top: 60px;">
      <div class="container">
         <div class="row">
            <div class="section_tittle">
               <h2>{{EventoTitulo}}</h2>
            </div>
         </div>
         <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12 mb-5">
               <div class="about_text">
                  <p v-html="EventoTexto"></p>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!--::team part end::-->


<!-- Blog Section Begin -->
<section class="blog spad" style="padding-top: 60px;padding-bottom: 60px;">
    <div class="container">
        <div class="row mb-5" v-for="artigo in artigos" :key="artigo.institucionalId">
                <div class="col-lg-4 col-md-6 mb-5">
                    <img v-if="artigo.institucionalImg" :src="$apiUrlIndex + artigo.institucionalImg[0].urlImagem" alt="" style="width: 100%;border-radius: 15px;">
                </div>
                <div class="col-lg-8 col-md-6 mb-5">
                  <h2>{{ artigo.nome}}</h2>
                     <p v-html="artigo.resumo1"></p>
                </div>
                <hr/>
        </div>
    </div>
    </section>
    <!-- Blog Section End -->

  </div>
</template>

<script>
import Header from '../shared/Header.vue'
// import Depoimentos from '../shared/Depoimentos.vue'
import moment from 'moment'

export default {
  components: {
    Header
  },
  data() {
    return {
      contato: [],
      topoTitulo:'',
      textoTitulo:'',

      artigos: [],
      topoEventos: [],
      EventoTitulo:'',
      EventoTexto:'',
      
    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/açõestopo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome
      this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/açõestitulo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoEventos = retornoAPI

      this.EventoTitulo = this.topoEventos[0].nome
      this.EventoTexto = this.topoEventos[0].resumo1

    });


    this.$http
      .get(this.$apiUrl + "/institucional/telas/açõeslista")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.artigos = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                        if (a.ordem > b.ordem) {
                            return 1;
                        }
                        if (a.ordem < b.ordem) {
                            return -1;
                        }
                        return 0;
                    }).reverse();

      });

  },
  methods: {
    currentDateTime(vdate) {
      return moment(vdate).format('DD/MM/YYYY')
    }
  }
  
}

</script>

<style>
  .blog__item {
    -webkit-box-shadow: 0px 10px 21px rgb(99 133 136 / 16%);
    box-shadow: 0px 10px 21px rgb(99 133 136 / 16%);
    margin-bottom: 50px;
}

.blog__item__text {
    text-align: center;
    position: relative;
    padding: 38px 35px 30px;
}
</style>