<template>
    <div>
        <Header />
        <section class="carrinho">

            <section class="main-minha_conta">
                <CheckAcesso />
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3" style="background-color: #cccccc;padding: 20px;">
                            <ul class="lista-sidebar_links">
                                <h5>Geral</h5>
                                <li><router-link to="/meusDados" class="texto">Meus Dados</router-link></li>
                                <li><router-link to="/mural" class="texto active">Mural Recados</router-link></li>
                                <li><router-link to="/contatosInterno" class="texto">Contatos</router-link></li>
                                <hr>
                                <h5>Informações</h5>
                                <li><router-link to="/atas" class="texto">Atas</router-link></li>
                                <li><router-link to="/contratos" class="texto">Contratos</router-link></li>
                                <li><router-link to="/estatuto" class="texto">Estatuto</router-link></li>
                                <hr>
                                <h5>Financeiro</h5>
                                <li><router-link to="/extratos" class="texto">Extratos</router-link></li>
                                <li><router-link to="/informativos" class="texto">Informativos</router-link></li>
                                <hr>
                                <h5>Comunicação</h5>
                                <li><router-link to="/eventosInterno" class="texto">Eventos</router-link></li>
                                <li><router-link to="/acoesInterno" class="texto">Ações</router-link></li>
                                <hr>
                            </ul>
                        </div>
                        <div class="col-lg-8 mt-5 mt-lg-0">
                            <h3 class="titulo-principal text-center mb-4">Mural de Recados</h3>
                            <div class="container">
                                <div class="row" v-for="item in mural" :key="item.institucionalId">
                                    <div class="col-lg-12">
                                      <span class="subtitulo h5 mb-4">{{ item.nome }} - Data: {{ FormatDateTime(item.data1) }} </span><br>
                                      <p v-html="item.resumo1" style="padding: 30px;"></p>
                                      <img v-if="item.institucionalImg!=''" :src="$apiUrlIndex + item.institucionalImg[0].urlImagem" title="" alt=""  style="width: 100%; padding-bottom: 30px;"/>
                                      <iframe v-if="item.campoExtra1!='' && item.campoExtra1!=null" width="100%" height="315" :src="item.campoExtra1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                    <hr style="margin-top: 20px;margin-bottom: 20px;">
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </div>
</template>

<script>
import Header from '../shared/Header.vue'
import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'
import moment from 'moment';

export default {
    components: {
        CheckAcesso,
        Header
    },
    data() {
        return {
            mural:[],
        }
    },
    methods: {
        currentDateTime(vdate) {
                //moment.locale("pt-br");
                //moment().endOf('second');
                //dateFormat(this.Cotacao.dataDia, "yyyy-mm-dd");
                //currentDateTime(Cotacao.dataDia)
                //return moment(vdate).format('DD/MM/YYYY')
                if (vdate != null) {
                    return moment(vdate).format('LL');
                }
                else {
                    return '';
                }

            },
            FormatDateTime(vdate) {
                moment.locale("pt-br");
                return moment(vdate).format('DD/MM/YYYY')
            },
    },
    created() {
        this.$http
            .get(this.$apiUrl + "/institucional/telas/muralderecados")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.mural = retornoAPI.filter(x => x.visivel  && new Date(x.data1) >= new Date(new Date().getTime() - 15 * 24 * 60 * 60 * 1000)).sort(function (a, b) {
                        if (a.data1 > b.data1) {
                            return 1;
                        }
                        if (a.data1 < b.data1) {
                            return -1;
                        }
                        return 0;
                    }).reverse();
            });
    }
}

</script>

<style scoped>
@import '/css_cart/carrinho.css';
@import '/css_cart/minha_conta.css';
</style>