<template>

<div>
  <Header />

   <!--::breadcrumb part start::-->
   <section class="breadcrumb" :style="{ backgroundImage: 'url('+ topo_bg + ')', height:'300px', backgroundRepeat:'no-repeat', backgroundSize: 'cover' }">
      <div class="container">
         <div class="row">
            <div class="col-lg-12">
               <div class="breadcrumb_iner">
                  <div class="breadcrumb_iner_item">
                     <h2>{{topoTitulo}}</h2>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!--::breadcrumb part start::-->

 <!--::team part end::-->
 <section class="about_part" style="padding-top: 60px;">
      <div class="container">
         <div class="row">
            <div class="section_tittle">
               <h2>{{topoTitulo}}</h2>
            </div>
         </div>
      </div>
   </section>
   <!--::team part end::-->
   <!-- ================ contact section start ================= -->
   <section class="contact-section" style="padding-bottom: 60px;">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <form class="form-contact contact_form" id="contactForm" novalidate="novalidate">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <input class="form-control placeholder hide-on-focus" v-model="nome" type="text" placeholder="Seu Nome">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input class="form-control placeholder hide-on-focus" v-model="email" type="email" placeholder="Seu E-mail">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input class="form-control placeholder hide-on-focus" v-model="telefone" type="email" placeholder="Seu Telefone">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input class="form-control placeholder hide-on-focus" v-model="assunto" type="text" placeholder="Assunto">
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                    <textarea class="form-control w-100 placeholder hide-on-focus" v-model="mensagem" cols="30" rows="9" placeholder="Mensagem"></textarea>
                </div>
              </div>
            </div>
            <div class="form-group mt-3">
              <button type="button" class="button button-contactForm" @click="enviarDadosFormulario">Enviar Mensagem</button>
           </div>
          </form>
        </div>
        <div class="col-lg-4">
          <div class="media contact-info">
            <span class="contact-info__icon"><i class="ti-home"></i></span>
            <div class="media-body">
              <p v-html="contatoEndereco"></p>
            </div>
          </div>
          <div class="media contact-info">
            <span class="contact-info__icon"><i class="ti-tablet"></i></span>
            <div class="media-body">
              <p>{{contatoTelefones}}</p>
            </div>
          </div>
          <div class="media contact-info">
            <span class="contact-info__icon"><i class="ti-email"></i></span>
            <div class="media-body">
              <p>{{contatoEmails}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d110507.44718632456!2d-51.161726!3d-30.055279!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x238ab292605707c9!2sASSOCIA%C3%87%C3%83O%20DE%20CONDOM%C3%8DNIOS%20CENTRAL%20PARQUE!5e0!3m2!1spt-BR!2sbr!4v1663115723719!5m2!1spt-BR!2sbr" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  </section>
  <!-- ================ contact section end ================= -->

  </div>
</template>

<script>
import Header from '../shared/Header.vue'
// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    Header
  },
  data() {
    return {
      contato: [],
      topoTitulo:'',
      textoTitulo:'',
      topo_bg:'',
      contatoEndereco: '',
      contatoTelefones: '',
      contatoEmails: '',
      contatoMapa:'',
      contatoImg:'',
      contatoEmail:'',
      assunto:'',
      // Objeto Contato  
      nome: '',
			email: '',
			telefone: '',
			mensagem: '',    
      Contato: {
        NomeEmpresa: "Central Parque Poa ",
        Assunto: "Contato",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }

    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/contatotopo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome
      this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.contato = retornoAPI
      if (this.contato!=''){
          this.topoTitulo = this.contato[0].nome
          this.contatoEmail = this.contato[0].referencia
          this.contatoEndereco = this.contato[0].resumo1
          this.contatoTelefones = this.contato[0].campoExtra1
          this.contatoEmails = this.contato[0].campoExtra2
      }
    });

  },
  methods: {
    enviarDadosFormulario() {

      //Email que irá receber os dados do formulário preenchido (empresa site)
      this.Contato.Destinatario = this.contatoEmail

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = 
        "Nome: " + this.nome + 
        " <br /> Assunto: " + this.assunto +
        " <br /> Telefone: " + this.telefone +
        " <br /> Email: " + this.email +
        " <br /> Descrição: " + this.mensagem

      //validar vazios
      if(this.nome == '' || this.telefone == '' || this.email == '' || this.mensagem == '') {
        this.$mensagem_normal("Preencha todos os campos");
      
      }else {

        //SE NAO TIVER ANEXO:
        // console.log(this.Contato)

        this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
        .then(response => {
          if(response.ok)
          this.$mensagem_normal("Enviado com sucesso!")
            this.nome = ''
            this.telefone = ''
            this.email = ''
            this.mensagem = ''
            this.assunto = ''
            this.Contato.Assunto=""
        },error => {
          this.$mensagem_normal(error.bodyText)
          this.nome = ''
          this.telefone = ''
          this.email = ''
          this.mensagem = ''
          this.assunto = ''
          this.Contato.Assunto=""
        });

      }
     
    }
  }
  
}

</script>

<style>
  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #cc9933 !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
</style>