<template>
    <div style="height: 100%;">
        <section class="" style="height: 100vh;background-image: url('/img/08.png');background-size: cover;background-repeat: no-repeat;">
            <div class="container">
                <div class="row py-5">
                    <div class="col-lg-12 text-center">
                        <div class="box_content" style="width: 300px; margin: 0 auto;">
                            <h4 class="titulo-principal text-center mb-5">Sistema Chamados</h4>
                            <div class="input-group mb-3">
                                <input v-model="Formulario.Usuario" type="text"
                                    class="form-control form-control-icone" placeholder="Usuário"
                                    onfocus="this.placeholder=''" onblur="this.placeholder='Usuário'">
                                <div class="input-group-append">
                                    <span class="input-group-text input-icone" id="#">
                                        <i class="fas fa-user"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <input v-model="Formulario.Senha" type="password"
                                    class="form-control form-control-icone" placeholder="Senha"
                                    onfocus="this.placeholder=''" onblur="this.placeholder='Senha'">
                                <div class="input-group-append">
                                    <span class="input-group-text input-icone" id="#">
                                        <i class="fas fa-lock"></i>
                                    </span>
                                </div>
                            </div>
                            <!-- <button class="btn btn-success w-100 mt-4">Fazer Login</button> -->
                            <button @click="ValidaCliente()"
                                class="btn btn-success w-100 mt-4">Acessar</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
//import Header from '../../shared/Header.vue'
import moment from 'moment';


export default {
    components: {
        //Header
    },
    data() {
        return {
            Titulos: {},
            Formulario: {
                Usuario: '',
                Senha: '',
            },
        }
    },
    created() {
        this.CheckLoginSistema();
    },
    methods: {
        ValidaCliente() {
            if (this.Formulario.Usuario == '') {
                this.$mensagem_normal("Preencha o usuário");
            }
            else if (this.Formulario.Senha == ''){
                this.$mensagem_normal("Preencha senha");
            }
            else {
                    this.$http
                        .get(this.$apiUrl + "/UsuariosSis")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.Usuarios = tiu2.filter(x => x.usuario.toLowerCase().trim() == this.Formulario.Usuario.toLowerCase().trim() &&  x.senha == this.Formulario.Senha);
                            if (this.Usuarios != ''){
                                let _dados = {
                                    nomeUsuario: '',
                                    usuarioId: '',
                                    usuario: '',
                                    senha: '',
                                    tipo: '',
                               }
                            _dados.nomeUsuario = this.Usuarios[0].nomeCompleto;
                            _dados.usuarioId = this.Usuarios[0].usuariosSisId;
                            _dados.usuario = this.Usuarios[0].usuario;
                            _dados.senha = this.Usuarios[0].senha;
                            _dados.tipo = this.Usuarios[0].tiposUsuariosSis.tiposUsuariosSisId;

                            this.$cookie.set(this.$DadosCookieSis, JSON.stringify(_dados), {
                                expires: "120m",
                            });
                            
                            this.$redirect("/ControleChamados");
                          
                            }
                            else{
                                this.$mensagem_normal("Usuário não identificado.");
                            }

                        });
            }
        },
        CheckLoginSistema() {
            let _dados = {
                    nomeUsuario: '',
                    usuarioId: '',
                    usuario: '',
                    senha: '',
                }
                _dados = JSON.parse(this.$cookie.get(this.$DadosCookieSis));
            if (_dados != null) {
                this.$redirect("/ControleChamados");
            }
        },
    }
}

</script>

<style scoped>
@import '/css_cart/carrinho.css';
@import '/css_cart/login.css';

.input-group-text {
    height: 38px;
}
</style>