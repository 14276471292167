import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/components/site/views/Home.vue'
import Sobre from '@/components/site/views/Sobre.vue'
import Fotos from '@/components/site/views/Fotos.vue'
import Blog from '@/components/site/views/Blog.vue'
import Videos from '@/components/site/views/Videos.vue'
import Eventos from '@/components/site/views/Eventos.vue'
import EventoDetalhe from '@/components/site/views/EventoDetalhe.vue'
import Acoes from '@/components/site/views/Acoes.vue'
import Contato from '@/components/site/views/Contato.vue'
import Informacoes from '@/components/site/views/Informacoes.vue'

import Login from '@/components/site/views/Login.vue'
import NovaContaLogin from '@/components/site/views/NovaContaLogin.vue'
import MeusDados from '@/components/site/views/PageMeusDados.vue'
import Mural from '@/components/site/views/Mural.vue'
import ContatosInterno from '@/components/site/views/ContatosInterno.vue'
import Atas from '@/components/site/views/Atas.vue'
import Contratos from '@/components/site/views/Contratos.vue'
import Estatuto from '@/components/site/views/Estatuto.vue'
import Extratos from '@/components/site/views/Extratos.vue'
import Informativos from '@/components/site/views/Informativos.vue'
import EventosInterno from '@/components/site/views/EventosInterno.vue'
import AcoesInterno from '@/components/site/views/AcoesInterno.vue'

import LoginSistema from '@/components/site/views/Sistema/LoginSistema.vue'
import ControleChamados from '@/components/site/views/Sistema/ControleChamados.vue'
import ChamadosPendentes from '@/components/site/views/Sistema/ChamadosPendentes.vue'
import ChamadosPesquisa from '@/components/site/views/Sistema/ChamadosPesquisa.vue'
import Indicadores from '@/components/site/views/Sistema/Indicadores.vue'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if(savedPosition) {
          return resolve(savedPosition)
        }
        if(to.hash) {
          return resolve({
            selector: to.hash,
            offset: {x: 0, y: 0}
          })
        }
        return resolve({ 
          x: 0, y: 0,
          behavior: 'smooth'
        })
      }, 700) 
    })
  },
  routes: [
    {path: '/', component: Home},
    {path: '/sobre', component: Sobre},
    {path: '/fotos', component: Fotos},
    {path: '/blog', component: Blog},
    {path: '/blog/:idNoticia/:nome', component: Blog},
    {path: '/videos', component: Videos},
    {path: '/eventos', component: Eventos},
    {path: '/eventoDetalhe/:id/:nome', component: EventoDetalhe}, 
    {path: '/acoes', component: Acoes},
    {path: '/contato', component: Contato}, 
    {path: '/informacoes/:id/:nome', component: Informacoes}, 
    {path: '/login', component: Login },
    {path: '/novaContaLogin', component: NovaContaLogin },
    {path: '/meusDados', component: MeusDados },
    {path: '/mural', component: Mural },
    {path: '/contatosInterno', component: ContatosInterno },
    {path: '/atas', component: Atas },
    {path: '/contratos', component: Contratos },
    {path: '/estatuto', component: Estatuto },
    {path: '/extratos', component: Extratos },
    {path: '/informativos', component: Informativos },
    {path: '/eventosInterno', component: EventosInterno },
    {path: '/acoesInterno', component: AcoesInterno },
    {path: '/LoginSistema', component: LoginSistema },
    {path: '/ControleChamados', component: ControleChamados },
    {path: '/ChamadosPendentes', component: ChamadosPendentes },
    {path: '/ChamadosPesquisa', component: ChamadosPesquisa },
    {path: '/Indicadores', component: Indicadores },
  ]
})