<template>
    <div>
        <section class="" style="min-height:600px;padding-top: 0px; padding-bottom: 150px;">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-2" style="background-color: #cccccc;padding-top: 20px;padding-left: 10px;padding-right: 10px;">
                            <div v-if="Turno != ''"
                            style="border:1px solid #cccccc;border-radius: 5px;background-color: #ffffff;padding: 10px;font-size: 14px;">
                            <div style="padding: 10px; text-align: center;">
                                <img src="/img/logo.png" />
                            </div>
                            <ul class="lista-sidebar_links" style="padding-left: 0rem !important;line-height: 1.8;">
                                <li><strong>Turno:</strong> {{ Turno[0].tiposTurnos.descricao }}</li>
                                <li><strong>Início:</strong> {{ FormatDateTime2(Turno[0].dataInicial) }}</li>
                                <li><strong>Usuário:</strong> {{ Turno[0].usuariosSis.usuario }}</li>
                                <li><strong>Matrícula:</strong> {{ Turno[0].usuariosSis.matricula }}</li>
                                <li v-if="Alfas != '' && Alfas != null && Turno[0].alfa1 !=null"><strong>Alfa 1:</strong> {{ Alfas.filter(x => x.alfasId ==
                                    Turno[0].alfa1) != null ? Alfas.filter(x => x.alfasId ==
                                    Turno[0].alfa1)[0].descricao : '' }}</li>
                                <li v-if="Alfas != '' && Alfas != null && Turno[0].alfa2 !=null"><strong>Alfa 2:</strong> {{ Alfas.filter(x => x.alfasId ==
                                    Turno[0].alfa2) != null ? Alfas.filter(x => x.alfasId ==
                                    Turno[0].alfa2)[0].descricao : '' }}</li>
                            </ul>
                        </div>
                            
                            <ul class="lista-sidebar_links" style="padding-left: 1rem !important;padding-top: 20px;">
                                <h5>Chamados</h5>
                                <li><router-link to="/ControleChamados" class="texto" style="font-size: 14px;">Turno Atual</router-link></li>
                                <li><router-link to="/ChamadosPendentes" class="texto" style="font-size: 14px;">Chamados Pendentes <small style="color:red">({{ itemsQtde }})</small></router-link></li>
                                <li><router-link to="/ChamadosPesquisa" class="texto" style="font-size: 14px;">Lista Chamados</router-link></li>
                                <li v-if="UsuarioLogado.tipo == 1"><router-link to="/Indicadores" class="texto" style="font-size: 14px;">Indicadores</router-link></li>
                                <hr>
                            </ul>
                        </div>
                        <div class="col-lg-10 mt-5 mt-lg-0">
                            <div class="container-fluid" style="padding-top: 20px;">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <button class="btn btn-secondary" @click="Logout()">
                                           <i class="fas fa-sign-out-alt"></i> Sair
                                        </button>
                                        <h3 class="titulo-principal text-center mb-4">Sistema de Chamados</h3>
                                    </div>
                                    <div class="col-lg-3">
                                        <button @click="IniciaTurno()" class="btn btn-success w-100" v-if="StatusIniciarTurno && LiberaAlteracao">Iniciar Turno</button>
                                    </div>
                                    <div class="col-lg-3">
                                        <button @click="EncerraTurno()" class="btn btn-warning w-100" v-if="StatusFinalizarTurno && LiberaAlteracao">Encerrar Turno</button>
                                    </div>
                                </div>   
                                <div class="row" v-if="MsgTurno!=''">
                                     <div class="col-lg-12">
                                        {{ MsgTurno }}
                                     </div>
                                </div>                            
                            </div>
                            <div class="container-fluid" v-if="StatusCadastrarTurno && LiberaAlteracao">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <div class="form-group" v-if="TiposTurnos!=''">
                                            <small>Turno</small>
                                            <v-select v-model="selecioneTipoTurno"
                                                :options="TiposTurnos"
                                                item-value="alfasId"
                                                item-text="descricao"
                                                single-line
                                                label="descricao" class="style-chooser"></v-select>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group" v-if="Alfas!=''">
                                            <small>Alfa 1</small>
                                            <v-select v-model="selecioneAlfa1"
                                                :options="Alfas"
                                                :reduce="descricao => descricao.alfasId"
                                                single-line
                                                label="descricao" class="style-chooser"></v-select>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group"  v-if="Alfas!=''">
                                            <small>Alfa 2</small>
                                            <v-select v-model="selecioneAlfa2"
                                                :options="Alfas"
                                                :reduce="descricao => descricao.alfasId"
                                                single-line
                                                label="descricao" class="style-chooser"></v-select>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <button @click="AddTurno()" class="btn btn-success w-100">Salvar</button>
                                    </div>
                                </div>
                            </div>
                            <div class="container-fluid" v-if="StatusFinalizarTurno">
                                <form class="form-contact contact_form" id="contactForm" novalidate="novalidate" v-if="LiberaAlteracao">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group" v-if="TipoOcorrencia!=''">
                                            <small>Tipo Ocorrência</small>
                                            <v-select v-model="SelecioneTipoOcorrencia"
                                                :options="TipoOcorrencia"
                                                item-value="tiposOcorrenciasId"
                                                item-text="descricao"
                                                single-line
                                                label="descricao" class="style-chooser"></v-select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <small>Hora Ocorrência</small>
                                            <input class="form-control" v-model="Ocorrencia.DataHoraOcorrencia" type="datetime-local" placeholder="Hora Ocorrência" style="Height: 35px !important;">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <small>Status Ocorrência</small>
                                            <v-select v-model="SelecioneStatusOcorrencia"
                                                :options="StatusOcorrencia"
                                                item-value="statusOcorrenciasId"
                                                item-text="descricao"
                                                single-line
                                                label="descricao" class="style-chooser"></v-select>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <small>OCR Ocorrência</small>
                                            <textarea class="form-control w-100" v-model="Ocorrencia.DescricaoOcr" cols="30" rows="4" placeholder="OCR Ocorrência"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <small>Ação Tomada</small>
                                            <textarea class="form-control w-100" v-model="Ocorrencia.AcaoTomada" cols="30" rows="4" placeholder="Ação Tomada"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group mt-3">
                                            <button type="button" class="button button-contactForm" @click="AddOcorrencia()">Inserir Ocorrência</button>
                                        </div>
                                    </div>
                                </div>
                                </form>
                                <div class="row">
                                    <b-table
                                    :striped="striped"
                                    :bordered="bordered"
                                    :borderless="borderless"
                                    :outlined="outlined"
                                    :small="small"
                                    :hover="hover"
                                    :dark="dark"
                                    :fixed="fixed"
                                    :foot-clone="footClone"
                                    :no-border-collapse="noCollapse"
                                    :items="items"
                                    :fields="fields"
                                    style="font-size: 12px;"
                                    >
                                    <template #cell(dataHoraOcorrencia)="data">
                                        <span style="font-size: 12px;">{{ FormatDateTime(data.value) }}</span>
                                    </template>
                                    <template #cell(tiposOcorrencias)="data">
                                        <span style="font-size: 12px;">{{ data.value.descricao }}</span>
                                    </template>
                                    <template #cell(statusOcorrencias)="data">
                                        <select v-model="data.value.statusOcorrenciasId" class="form-control" style="font-size: 12px !important;">
                                            <option v-for="(status,index) in StatusOcorrencia" :key="index" :value="status.statusOcorrenciasId">{{ status.descricao }}</option>
                                        </select>
                                        </template>
                                    <template #cell(descricaoOcr)="data">
                                        <span style="font-size: 12px;">{{ data.value }}</span>
                                    </template>
                                    <template #cell(acaoTomada)="data">
                                        <span style="font-size: 12px;">{{ data.value }}</span>
                                    </template>
                                   
                                    <template #cell(save)="row">
                                        <b-button v-if="LiberaAlteracao" size="sm" @click="SaveStatus(row.item.chamadosTurnosId, row.item.statusOcorrencias.statusOcorrenciasId)" class="mr-1 btn-success">
                                           Salvar
                                        </b-button>
                                    </template>
                                    <template #cell(actions)="row">
                                        <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1 btn-primary">
                                           Ações
                                        </b-button>
                                    </template>
                                  </b-table>
                                    <!-- Info modal -->
                                    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
                                        <form class="form-contact contact_form" id="contactForm" novalidate="novalidate">
                                            <div class="container-fluid">
                                                <div class="row" v-if="LiberaAlteracao">
                                                    <div class="col-lg-12">
                                                        <div class="form-group">
                                                            <small>Ação Tomada</small>
                                                            <textarea class="form-control w-100" v-model="Acao.Descricao" cols="30" rows="4" placeholder="Ação Tomada"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <small>Data Hora Ação</small>
                                                            <input class="form-control" v-model="Acao.DataHoraAtualizacao" type="datetime-local" placeholder="Hora Ocorrência" style="Height: 35px !important;">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group mt-3">
                                                            <button type="button" class="button button-contactForm" @click="AddAcao(infoModal.content)">Inserir Ação</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <b-table striped hover :items="itemsAcoes" :fields="fieldsAcoes">
                                                                <template #cell(dataHoraAtualizacao)="data">
                                                                    <span style="font-size: 12px;">{{ FormatDateTime(data.value) }}</span>
                                                                </template>
                                                            </b-table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </b-modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    </div>
</template>

<script>
import moment from 'moment';
import vSelect from 'vue-select';

export default {
    components: {
        vSelect
    },
    data() {
        return {
            Turno:[],
            MsgTurno:'',
            Alfas:[],
            selecioneAlfa1:'',
            selecioneAlfa2:'',
            TiposTurnos:[],
            selecioneTipoTurno:'',
            StatusIniciarTurno:false,
            StatusFinalizarTurno:false,
            StatusCadastrarTurno: false,
            UsuarioLogado:[],
            Ocorrencia: {
                ChamadosTurnosId:0,
                Turnos: { TurnosId: ''},
                UsuariosSis: { UsuariosSisId: ''},
                TiposOcorrencias: { TiposOcorrenciasId: ''},
                DataHoraRegistro:'',
                DataHoraOcorrencia:'',
                DescricaoOcr:'',
                OcrOcorrencia:'',
                AcaoTomada:'',
                StatusOcorrencias:{ StatusOcorrenciasId: ''}
            },
            TipoOcorrencia:[],
            StatusOcorrencia:[],
            SelecioneTipoOcorrencia:'',
            SelecioneStatusOcorrencia:'',
            ///
            Acao:{
                ChamadosAcoesId:0,
                ChamadosTurnos: { ChamadosTurnosId: ''},
                UsuariosSis: { UsuariosSisId: ''},
                DataHoraRegistro:'',
                DataHoraAtualizacao:'',
                Descricao:''
            },
            itemsAcoes:[],
            fieldsAcoes: [{ key: 'chamadosAcoesId', label: 'Id' }
                        , { key: 'dataHoraAtualizacao', label: 'Data Hora', sortable: true, thStyle: { width: '20%' }}
                        , { key: 'descricao', label: 'Descrição',thStyle: { width: '85%' } }
                        , { key: 'usuariosSis.usuario', label: 'Usuário',thStyle: { width: '5%' } }
                      ],
            ///
            fields: [{ key: 'chamadosTurnosId', label: 'Id' }
                   , { key: 'tiposOcorrencias', label: 'Tipo', sortable: true, thStyle: { width: '10%' } }
                   , { key: 'dataHoraOcorrencia', label: 'Data Hora', sortable: true, thStyle: { width: '10%' }}
                   , { key: 'descricaoOcr', label: 'OCR Ocorrência',thStyle: { width: '25%' } }
                   , { key: 'acaoTomada', label: 'Ação Tomada', thStyle: { width: '25%' } }
                   , { key: 'usuariosSis.usuario', label: 'Usuário',thStyle: { width: '5%' } }
                   , { key: 'statusOcorrencias', label: 'Status',thStyle: { width: '15%' } }
                   , { key: 'save', label: 'Salvar' }
                   , { key: 'actions', label: 'Ações' }],
            items: [],
        striped: true,
        bordered: true,
        borderless: false,
        outlined: true,
        small: true,
        hover: true,
        dark: false,
        fixed: false,
        footClone: false,
        noCollapse: false,
        //
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        },
        LiberaAlteracao:false,
        itemsQtde:0,
     }
    },
    methods: {
        Logout(){
            console.log('aqui')
            this.$cookie.delete(this.$DadosCookieSis);
            this.$redirect("/LoginSistema");
        },
        CheckLoginSistema() {
            let _dados = {
                    nomeUsuario: '',
                    usuarioId: '',
                    usuario: '',
                    senha: '',
                    tipo:'',
                }
            _dados = JSON.parse(this.$cookie.get(this.$DadosCookieSis));
            if (_dados == null) {
                this.$redirect("/LoginSistema");
            }
            else{
                this.UsuarioLogado = _dados;
            }
        },
        currentDateTime(vdate) {
                if (vdate != null) {
                    return moment(vdate).format('LL');
                }
                else {
                    return '';
                }

        },
        FormatDateTime(vdate) {
            moment.locale("pt-br");
            return moment(vdate).format('DD/MM/YYYY HH:mm')
        },
        FormatDateTime2(vdate) {
            moment.locale("pt-br");
            return moment(vdate).subtract(3, 'hours').format('DD/MM/YYYY HH:mm')
        },
        IniciaTurno(){
          this.StatusCadastrarTurno = true;
          this.MsgTurno = '';
        },
        EncerraTurno(){
          //encerra turno
          let _Turno = {
                    TurnosId: this.Turno[0].turnosId,
                    TiposTurnos: {TiposTurnosId: this.Turno[0].tiposTurnos.tiposTurnosId },
                    UsuariosSis: {UsuariosSisId: this.Turno[0].usuariosSis.usuariosSisId },
                    Alfa1: this.Turno[0].alfa1 == "" ? null : this.Turno[0].alfa1,
                    Alfa2: this.Turno[0].alfa2 == "" ? null : this.Turno[0].alfa2,
                    DataInicial: this.Turno[0].dataInicial,
                    DataFinal: moment(),
                    Situacao: 'F',
                };

          console.log('this.Turno')
            console.log(this.Turno)
          
          this.$http.put(this.$apiUrl + "/Turnos/" + this.Turno[0].turnosId, _Turno).then(
                        (response3) => {
                        // get body data
                           this.$redirect_reload("/ControleChamados");
                        },
                        (response3) => {
                            // error callback
                            this.$mensagem_erro(response3.body);
                        }
                    );
        },
        AddTurno() {
            //validações
            if (this.selecioneTipoTurno == "") {
                this.$mensagem_normal("Selecione o Turno");
            } else if (this.selecioneAlfa1 == null) {
                this.$mensagem_normal("Selecione Alfa 1");
            } else {

                let _Turno = {
                    TurnosId: 0,
                    Alfa1: this.selecioneAlfa1 == "" ? null : this.selecioneAlfa1,
                    Alfa2: this.selecioneAlfa2 == "" ? null : this.selecioneAlfa2,
                    DataInicial: moment(),
                    Situacao: 'A',
                };
                console.log('_Turno')
                console.log(_Turno)
                this.$http.post(this.$apiUrl + "/Turnos/" + this.selecioneTipoTurno.tiposTurnosId + "/" + this.UsuarioLogado.usuarioId, _Turno).then(
                        (response3) => {
                        // get body data
                        _Turno.turnosId = response3.body;
                        this.$redirect_reload("/ControleChamados");
                        },
                        (response3) => {
                            // error callback
                            this.$mensagem_erro(response3.body);
                        }
                    );
            }
        },
        AddOcorrencia(){
            if (this.SelecioneTipoOcorrencia.tiposOcorrenciasId == "") {
                this.$mensagem_normal("Selecione o Tipo de Ocorrêcia");
            } else if (this.Ocorrencia.DataHoraOcorrencia == "") {
                this.$mensagem_normal("Preencha Data Hora da Ocorrência");
            } else if (this.SelecioneStatusOcorrencia.statusOcorrenciasId == "") {
                this.$mensagem_normal("Selecione Status da Ocorrência");
            } else if (this.Ocorrencia.DescricaoOcr == "") {
                this.$mensagem_normal("Preencha Descrição OCR da Ocorrência");
            } else if (this.Ocorrencia.AcaoTomada == "") {
                this.$mensagem_normal("Preencha Ação Tomada da Ocorrência");
            } else {

             this.Ocorrencia.Turnos.TurnosId = this.Turno[0].turnosId;
             this.Ocorrencia.UsuariosSis.UsuariosSisId = this.UsuarioLogado.usuarioId;
             this.Ocorrencia.DataHoraRegistro = moment();
             this.Ocorrencia.TiposOcorrencias.TiposOcorrenciasId = parseInt(this.SelecioneTipoOcorrencia.tiposOcorrenciasId);
             this.Ocorrencia.StatusOcorrencias.StatusOcorrenciasId = parseInt(this.SelecioneStatusOcorrencia.statusOcorrenciasId);
             console.log('this.Ocorrencia')
             console.log(this.Ocorrencia)
             this.$http.post(this.$apiUrl + "/ChamadosTurnos/" + this.Ocorrencia.Turnos.TurnosId + "/" + this.Ocorrencia.UsuariosSis.UsuariosSisId + '/' + this.Ocorrencia.TiposOcorrencias.TiposOcorrenciasId + '/' + this.Ocorrencia.StatusOcorrencias.StatusOcorrenciasId, this.Ocorrencia).then(
                        (response3) => {
                        // get body data
                        this.Ocorrencia.ChamadosTurnosId = response3.body;
                        this.$redirect_reload("/ControleChamados");
                        },
                        (response3) => {
                            // error callback
                            this.$mensagem_erro(response3.body);
                        }
                    );

            }
           
        },
        AddAcao(value){
            console.log('acoes')
                console.log(value)
            if (this.Acao.Descricao == "") {
                this.$mensagem_normal("Preencha Descrição da Ação");
            } else if (this.Acao.DataHoraAtualizacao == "") {
                this.$mensagem_normal("Preencha Data Hora da Ação");
            } else {
                this.Acao.ChamadosTurnos.ChamadosTurnosId = value.chamadosTurnosId;
                this.Acao.UsuariosSis.UsuariosSisId = this.UsuarioLogado.usuarioId;
                this.Acao.DataHoraRegistro = moment();

                this.$http.post(this.$apiUrl + "/ChamadosAcoes/" + this.Acao.ChamadosTurnos.ChamadosTurnosId + "/" + this.Acao.UsuariosSis.UsuariosSisId, this.Acao).then(
                        (response3) => {
                        // get body data
                        this.Acao.ChamadosAcoesId = response3.body;
                        this.GetItemsAcoes(this.Acao.ChamadosTurnos.ChamadosTurnosId);
                        this.Acao.ChamadosAcoesId=0;
                        this.Acao.DataHoraRegistro='';
                        this.Acao.DataHoraAtualizacao='';
                        this.Acao.Descricao='';
                        },
                        (response3) => {
                            // error callback
                            this.$mensagem_erro(response3.body);
                        }
                    );
            }
        },
        info(item, index, button) {
          this.infoModal.title = `Ações Ocorrência : ` + item.chamadosTurnosId + ' - ' + item.tiposOcorrencias.descricao;
          this.infoModal.content = item;
          this.$root.$emit('bv::show::modal', this.infoModal.id, button)
          this.GetItemsAcoes(item.chamadosTurnosId);
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      SaveStatus(turnoId, statusId) {
        console.log('turnoId,statusId')
        console.log(turnoId)
        console.log(statusId)
        this.$http.put(this.$apiUrl + "/ChamadosTurnos/" + turnoId + "/" + statusId).then(
                        (response3) => {
                        // get body data
                        turnoId = response3.body;
                        this.$redirect_reload("/ControleChamados");
                        },
                        (response3) => {
                            // error callback
                            this.$mensagem_erro(response3.body);
                        }
                    );
      },
      GetItemsAcoes(value){
        this.$http
            .get(this.$apiUrl + "/ChamadosAcoes/ChamadosItensAcoes/" + value)
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.itemsAcoes = retornoAPI
                console.log('lista açoes')
                console.log(this.itemsAcoes)
            });
        
      },
    },
    created() {
        this.CheckLoginSistema();

        this.$http
            .get(this.$apiUrl + "/Turnos")
            .then((res) => res.json())
            .then((retornoAPI) => {
            this.Turno = retornoAPI.filter(x=> x.situacao == 'A')

            console.log('this.Turno')
            console.log(this.Turno)

            if (this.Turno!= ""){
                this.StatusIniciarTurno = false;
                this.StatusFinalizarTurno = true;

               if (this.UsuarioLogado.usuarioId == this.Turno[0].usuariosSis.usuariosSisId || this.UsuarioLogado.tipo == 1){
                 this.LiberaAlteracao = true;
               }
                this.$http
                    .get(this.$apiUrl + "/ChamadosTurnos/Turno/" + this.Turno[0].turnosId )
                    .then((res) => res.json())
                    .then((retornoAPI) => {
                        this.items = retornoAPI
                    });


            }
            else{
                this.MsgTurno = "Não existe Turno Ativo."
                this.StatusIniciarTurno = true;
                this.StatusFinalizarTurno = false;
                this.LiberaAlteracao = true;

            }
         });

         this.$http
            .get(this.$apiUrl + "/Alfas")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.Alfas = retornoAPI.filter(x=> x.visivel)
                console.log('this.Alfas')
            console.log(this.Alfas)
            });

            this.$http
            .get(this.$apiUrl + "/TiposTurnos")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.TiposTurnos = retornoAPI.filter(x=> x.visivel)
            });

            this.$http
            .get(this.$apiUrl + "/TiposOcorrencias")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.TipoOcorrencia = retornoAPI.filter(x=> x.visivel).sort(function (a, b) {
                    if (a.descricao > b.descricao) {
                        return 1;
                    }
                    if (a.descricao < b.descricao) {
                        return -1;
                    }
                    return 0;
                });
            });

            this.$http
            .get(this.$apiUrl + "/StatusOcorrencias")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.StatusOcorrencia = retornoAPI.filter(x=> x.visivel)
            });

            this.$http
        .get(this.$apiUrl + "/ChamadosTurnos/Status/1")
        .then((res) => res.json())
        .then((retornoAPI) => {
            this.itemsQtde = retornoAPI.length
        });

         
    }
}

</script>

<style>
@import '/css_cart/carrinho.css';
@import '/css_cart/minha_conta.css';


@media (min-width: 976px){
    .modal-dialog {
        max-width: 700px !important;
        margin: 1.75rem auto;
    }
}

</style>