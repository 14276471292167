<template>

  <div>
 </div>

</template>

<script>

    export default {

        data() {
            return {
                usuario: {},
            }
        },
        created() {
            this.CheckLogin();
        },
        methods: {
            CheckLogin() {
                let usuarioTmp = {
                    email: '',
                    senha: '',
                }
                usuarioTmp = JSON.parse(this.$cookie.get(this.$DadosCookie));
                if (usuarioTmp != null) {
                    let _usuario = {
                        email: usuarioTmp.email,
                        senha: usuarioTmp.senha,
                    };
                    this.$http.post(this.$apiUrl + "/clientes/login", _usuario).then(
                        (response) => {
                            // get body data
                                this.usuario = response.body;
                        },
                        (response2) => {
                            // error callback
                            this.$mensagem_erro(response2.body);
                            this.$redirect_reload("/login")
                        }
                    );
                } else {
                    this.$redirect_reload("/login");
                }
            },
            Logout() {
                this.$cookie.delete(this.$DadosCookie);
                this.$redirect_reload("/login");
            },
        }
    }
</script>