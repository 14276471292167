<template>
    <div>
        <Header />
        <section class="carrinho">

            <section class="main-minha_conta">
                <CheckAcesso />
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3" style="background-color: #cccccc;padding: 20px;">
                            <ul class="lista-sidebar_links">
                                <h5>Geral</h5>
                                <li><router-link to="/meusDados" class="texto">Meus Dados</router-link></li>
                                <li><router-link to="/mural" class="texto">Mural Recados</router-link></li>
                                <li><router-link to="/contatosInterno" class="texto">Contatos</router-link></li>
                                <hr>
                                <h5>Informações</h5>
                                <li><router-link to="/atas" class="texto">Atas</router-link></li>
                                <li><router-link to="/contratos" class="texto active">Contratos</router-link></li>
                                <li><router-link to="/estatuto" class="texto">Estatuto</router-link></li>
                                <hr>
                                <h5>Financeiro</h5>
                                <li><router-link to="/extratos" class="texto">Extratos</router-link></li>
                                <li><router-link to="/informativos" class="texto">Informativos</router-link></li>
                                <hr>
                                <h5>Comunicação</h5>
                                <li><router-link to="/eventosInterno" class="texto">Eventos</router-link></li>
                                <li><router-link to="/acoesInterno" class="texto">Ações</router-link></li>
                                <hr>
                            </ul>
                        </div>
                        <div class="col-lg-8 mt-5 mt-lg-0">
                            <h3 class="titulo-principal text-center mb-4">Contratos</h3>
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-4 mt-4 mb-4" style="text-align: center;padding-top: 30px;" v-for="item in myArray"
                                        :key="item.institucionalId">
                                        <a :href="item.campoExtra2" target="_blank">
                                            <img src="img/pdfimg.jpg" title="" alt=""
                                                style="width: 50px; padding-bottom: 10px;" /><br>
                                            <strong style="color:#000000;">{{ item.nome }} </strong><br>
                                        </a>
                                        <p v-html="item.resumo1" style="padding: 5px;"></p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </div>
</template>

<script>
import Header from '../shared/Header.vue'
import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'
import moment from 'moment';

export default {
    components: {
        CheckAcesso,
        Header
    },
    data() {
        return {
            myArray: []
        }
    },
    methods: {
        currentDateTime(vdate) {
            if (vdate != null) {
                return moment(vdate).format('LL');
            }
            else {
                return '';
            }

        },
        FormatDateTime(vdate) {
            moment.locale("pt-br");
            return moment(vdate).format('DD/MM/YYYY')
        },
    },
    created() {
        this.$http
            .get(this.$apiUrl + "/institucional/telas/contratos")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.myArray = retornoAPI.filter(x => x.visivel)
            });
    }
}

</script>

<style scoped>@import '/css_cart/carrinho.css';
@import '/css_cart/minha_conta.css';</style>